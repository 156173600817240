import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Grid, Button, Loader } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import $ from "jquery";
import server from "../../functions/server";
import domain from "../../functions/domain";
import UserId from "../../functions/userid";
import Network from "../../Utilis/Network";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

class Status extends React.Component {
  state = {
    activeStep: this.props.status,
    skipped: new Set(),
    errorDialog: false,
  };

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  getSteps() {
    return ["Ordered", "Order Conformed", "Packing", "Shipping", "Delivered"];
  }

  cancelOrder() {
    let context = this;
    let userId = UserId();
    let data1 = { orderID: this.props.orderID, userId: userId };
    context.setState({ loading: true });
    let url = server() + "/order/cancel.php";
    Network.POST(
      url,
      data1,
      (data) => {
        console.log(data);
        context.setState({
          loading: false,
        });
        if (data === "fail") {
        } else {
          context.props.updateMyOrder(data);
        }
      },
      (err) => {
        context.setState({
          errorDialog: true,
          loading: false,
        });
      }
    );
  }

  reOrder() {
    var url_string =
      domain() +
      "product?shop=" +
      this.props.shopId +
      "&product=" +
      this.props.product_id; //window.location.href
    window.open(url_string);
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    let cancelOrder = <div />;
    let reOrder = <div />;
    let error = <div />;
    let returns = <div />;
    if (this.props.status < 4 && this.props.status > 0) {
      cancelOrder = (
        <Button basic color="red" fluid onClick={this.cancelOrder.bind(this)}>
          Cancel Order
        </Button>
      );
    }
    if (this.props.status <= 0) {
      cancelOrder = (
        <Button
          basic
          color="red"
          fluid
          disabled
          onClick={this.cancelOrder.bind(this)}
        >
          Cancel Order
        </Button>
      );
      error = "This order is cancelled!!!";

      reOrder = (
        <Button basic color="blue" fluid onClick={this.reOrder.bind(this)}>
          Re-Order
        </Button>
      );
    }

    if (this.props.status === 5) {
      returns = (
        <Button basic color="blue" style={{ display: "none" }}>
          Return
        </Button>
      );
    }

    return (
      <div className={classes.root}>
        <Grid>
          <Grid.Column mobile={10} tablet={10} computer={10}>
            <Stepper activeStep={this.props.status} alternativeLabel>
              {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                return (
                  <Step style={{ paddingRight: "0.1%" }}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <center style={{ color: "red" }}>
              <b>{error}</b>
            </center>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={2}
            style={{ paddingTop: "4%" }}
          >
            {reOrder}
            {returns}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={3}
            style={{ paddingTop: "4%" }}
          >
            {cancelOrder}
          </Grid.Column>
        </Grid>
        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Status.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Status);
