import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import server from "../functions/server";
import domain from "../functions/domain";
import Products from "./products";
import Category from "./category";
import Searchproduct from "./searchproduct";
import Title from "./title";
import DocumentMeta from "react-document-meta";
import Network from "../Utilis/Network";

class Home extends React.Component {
  state = {
    data: [],
    shopName: "",
  };

  getTitle() {
    try {
      let title = window.location.href;
      title = title.split(domain())[1];
      title = title
        .split("-")
        .join(" ")
        .trim()
        .split("/")[0];
      return title;
    } catch (e) {
      return null;
    }
  }

  getCategory() {
    try {
      let title = window.location.href;
      title = title
        .split(domain())[1]
        .split("/")[1]
        .split("-")
        .join(" ")
        .trim();
      if (title.length >= 1) {
        return title;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  search(search) {
    let context = this;
    let shopName = window.location.href;
    shopName = shopName
      .split("/")[3]
      .split("-")
      .join(" ");
    let payload = { search: search, allshop: "all", shopName: shopName };
    context.setState({ search: search });
    let url = server() + "/product/search.php";
    Network.POST(
      url,
      payload,
      (data) => {
        console.log("dataaaa");
        context.setState({ data, shopName });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  badgeUpdate() {
    this.setState({ dumy: true });
  }

  render() {
    let shopName = this.state.shopName;
    let x = this.getCategory() ? (
      <Category
        badgeUpdate={this.badgeUpdate.bind(this)}
        name={this.getTitle()}
        category={this.getCategory()}
      />
    ) : (
      <Products
        badgeUpdate={this.badgeUpdate.bind(this)}
        name={this.getTitle()}
      />
    );
    let title = this.getCategory()
      ? this.getCategory() + " - " + this.getTitle()
      : this.getTitle();
    return (
      <div style={{ paddingBottom: 30, paddingTop: 70 }}>
        <Title title={title} search={this.search.bind(this)} />
        {this.state.search ? (
          <Searchproduct
            badgeUpdate={this.badgeUpdate.bind(this)}
            search={this.state.search}
            data={this.state.data}
          />
        ) : (
          <div>{x}</div>
        )}
      </div>
    );
  }
}

export default Home;
