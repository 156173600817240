import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'semantic-ui-css/semantic.min.css';
import blue from '@material-ui/core/colors/blue';
import './App.css';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './home';
import Shop from './shop';
import Product from './product/view';
import ProductRest from './product/viewrest';
import Order from './order';
import Payment from './order/payment';
import MyOrder from './order/myorder/myorder';
import Shopslist from './allshops';
import Socialmedia from './socialmedia';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  }
});

class App extends Component {

  constructor() {
    super();
    this.state = { load: false }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path="/socialmedia*" component={Socialmedia} />
            <Route path="/shops/*" component={Shopslist} />
            <Route exact path="/" component={Home} />
            <Route exact path="/product" component={Product} />
            <Route exact path="/product/:city/:shopName/:productName/:shopId/:productId" component={ProductRest} />
            <Route exact path="/product/:city/:shopName/:area/:productName/:shopId/:productId" component={ProductRest} />
            <Route exact path="/order" component={Order} />
            <Route exact path="/myorders" component={MyOrder} />
            <Route exact path="/payment" component={Payment} />
            <Route path="/*" component={Shop} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
