import React, { Component } from "react";
import { Grid, Image, Card, Button, Input, Loader } from "semantic-ui-react";
import $ from "jquery";
import server from "../../functions/server";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import cartCount from "../../functions/cart";
import UserId from "../../functions/userid";
import Network from "../../Utilis/Network";

class Viewcart extends Component {
  state = {
    stockError: "",
    showDialog: false,
    quantity: this.props.quantity,
    loading: false,
    errorDialog: false,
  };

  componentDidMount() {
    this.quantityChange(this.props.id);
  }

  quantityChange(e, x) {
    let context = this;
    let id = this.props.id;
    let userId = UserId();
    let quantity = 0;
    if (x) {
      quantity = x.value;
    } else {
      document.getElementById(id).value = 0;
    }
    context.setState({
      stockError: "",
      quantity: quantity,
    });
    if (quantity == null || quantity === "") {
      return;
    }
    if (x && quantity === 0) {
      this.setState({ showDialog: true });
    } else {
      let payload = { quantity: quantity, productId: id, userId: userId };
      context.setState({ loading: true });
      let url = server() + "/product/checkquantity.php";
      Network.POST(
        url,
        payload,
        (data) => {
          data = Number(data);
          let newQuantity = 0;
          let no_stock = 0;
          if (quantity <= data) {
            context.setState({
              loading: false,
              stockError: "Out of Stock",
            });
          } else {
            context.setState({
              stockError: "There is only " + data + " product`s are available",
              loading: false,
            });
            document.getElementById(id).value = data;
            quantity = data;
          }
          newQuantity = quantity;
          no_stock = data;
          let cart = context.props.cart;
          for (var i = 0; i < cart.length; i++) {
            if (cart[i].cartId === context.props.cartId) {
              cart[i].quantity = newQuantity;
              cart[i].no_stock = no_stock;
            }
          }
          context.props.updateChart(cart);
        },
        (err) => {
          context.setState({ errorDialog: true, loading: false });
        }
      );
    }
  }

  removeItem(cartId) {
    let context = this;
    let userId = UserId();
    let payload = { chartId: cartId, userId: userId };
    let url = server() + "/cart/remove.php";
    context.setState({ loading: true });

    Network.POST(
      url,
      payload,
      (data) => {
        context.setState({
          showDialog: false,
          loading: false,
        });
        context.props.removeItem(cartId);
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  removeItemFromCart(cartId) {
    this.props.removeItem(cartId);
  }

  closeDialog() {
    this.setState({ showDialog: false });
    document.getElementById(this.props.id).value = this.props.quantity;
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  render() {
    let prize = this.props.prize;
    let tax = this.props.tax;
    try {
      prize = Number(prize);
      tax = Number(tax);
      prize = prize + (prize * tax) / 100;
      prize = prize.toFixed(2);
    } catch (e) {
      prize = 0;
    }
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Image floated="right" size="mini" src={this.props.feature_image} />
            <Card.Header>{this.props.product_name}</Card.Header>
            <Card.Meta style={{ color: "green" }}>
              <b>Rs: {prize}</b>
            </Card.Meta>
            <Card.Description>
              Quantity:
              <Input
                fluid
                type="number"
                id={this.props.id}
                value={this.props.quantity}
                onChange={this.quantityChange.bind(this)}
              />
              <p style={{ color: "red" }}>{this.state.stockError}</p>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button
                basic
                color="red"
                onClick={this.removeItem.bind(this, this.props.cartId)}
              >
                Remove from cart
              </Button>
              <Button
                basic
                color="red"
                onClick={this.removeItemFromCart.bind(this, this.props.cartId)}
              >
                Remove
              </Button>
            </div>
          </Card.Content>
        </Card>
        <Dialog
          open={this.state.showDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog1">
            <center>Remove item in cart</center>
          </DialogTitle>
          <DialogActions>
            <Button
              positive
              onClick={this.removeItem.bind(this, this.props.cartId)}
            >
              Remove
            </Button>
            <Button negative onClick={this.closeDialog.bind(this)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Viewcart;
