import React, { Component } from "react";
import { Card, Grid, Loader } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import $ from "jquery";
import server from "../../functions/server";
import View from "./view";
import Network from "../../Utilis/Network";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Ordermaster extends Component {
  state = {
    myorder: [],
  };

  getOrders(id) {
    let context = this;
    let data1 = { id: id };
    context.setState({ loading: true });
    let url = server() + "/order/getmyorderdetails.php";
    Network.POST(
      url,
      data1,
      (myorder) => {
        console.log(myorder);
        context.setState({
          loading: false,
        });
        try {
          context.setState({
            myorder: myorder,
            openOrder: true,
          });
        } catch (e) {
          alert("Try Again!!!");
        }
      },
      (err) => {
        alert("Try Again!!!");
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  updateorders(myorder) {
    this.setState({ myorder: myorder });
  }

  updateMyOrder(orderId) {
    let myOrder = this.state.myorder;
    for (var i = 0; i < myOrder.length; i++) {
      if (myOrder[i].id === orderId) {
        myOrder[i].status = 0 + "";
      }
    }
    this.setState({ myorder: myOrder });
  }

  myorders() {
    let order = [];
    this.state.myorder.map((z) => {
      order.push(
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <View
            updateorders={this.updateorders.bind(this)}
            myorder={this.state.myorder}
            product_id={z.product_id}
            userId={z.userId}
            shopId={z.shopId}
            rating={z.rating}
            pickuptime={z.pickuptime}
            review={z.review}
            productId={z.productId}
            updateMyOrder={this.updateMyOrder.bind(this)}
            product_name={z.product_name}
            date={z.date}
            price={z.amount}
            orderID={z.id}
            quantity={z.quantity}
            status={z.status}
            shopName={z.shop_name}
            type={z.type}
            country={z.country}
            state={z.state}
            city={z.city}
            street={z.street}
            pincode={z.pincode}
            phone={z.phone}
            name={z.name}
          />
        </Grid.Column>
      );
    });
    return order;
  }

  dateFormat(date) {
    let dd = date.split(" ")[0];
    let tt = date.split(" ")[1];
    dd =
      dd.split("-")[2] +
      "-" +
      dd.split("-")[1] +
      "-" +
      dd.split("-")[0] +
      " " +
      tt;
    return dd;
  }

  checkWebsiteAvailable(website) {
    let full = website;
    try {
      website = website.split("/")[2];
    } catch (e) {}
    if (website === "undefined") {
      return "";
    } else {
      return "Website : " + full;
    }
  }

  render() {
    let context = this;
    let myorder = <div />;
    myorder = context.myorders();
    return (
      <div>
        {this.props.ordermaster.map((c) => {
          let waitingforconformation = 0;
          let packing = 0;
          let shipping = 0;
          let delivered = 0;
          if (c.waitingforconformation) {
            waitingforconformation = c.waitingforconformation;
          }
          if (c.packing) {
            packing = c.packing;
          }
          if (c.shipping) {
            shipping = c.shipping;
          }
          if (c.delivered) {
            delivered = c.delivered;
          }
          return (
            <Card fluid onClick={this.getOrders.bind(this, c.id)}>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      {c.shop_name}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <div
                        align="right"
                        style={{ color: "#4390BF", fontSize: 14 }}
                      >
                        {"Order Id : " + c.id}
                      </div>
                    </Grid.Column>
                  </Grid>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={11}>
                    <Grid>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div style={{ display: "flex" }}>
                          <b>Total Items:</b>
                          <div style={{ paddingLeft: "5%" }}>{c.items}</div>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div style={{ display: "flex" }}>
                          <b>Total Amount:</b>
                          <div style={{ paddingLeft: "2%" }}>
                            {"Rs." + Number(c.price).toFixed(2)}
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        {c.type === "myself" ? (
                          <div style={{ display: "flex", color: "red" }}>
                            <b>Pickup Time:</b>
                            <div style={{ paddingLeft: "3%" }}>
                              {this.dateFormat(c.pickuptime)}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={5}>
                    {c.type === "myself" ? (
                      <b>Shop Address</b>
                    ) : (
                      <b>Delivery Address</b>
                    )}
                    <br />
                    {c.address + ","}
                    <br />
                    {c.city + ","}
                    <br />
                    {c.state + " - " + c.pincode}
                    <br />
                    {"Mobile No: " + c.phone}
                    <br />
                    {c.type === "myself" ? (
                      <b>{this.checkWebsiteAvailable(c.website)}</b>
                    ) : (
                      <b>{"Delivery IN : " + c.website}</b>
                    )}
                    <br />
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <div style={{ display: "flex" }}>
                      <b>Order Conformed :</b>
                      <div style={{ paddingLeft: "2%" }}>
                        {waitingforconformation}
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <div style={{ display: "flex" }}>
                      <b>Packed :</b>
                      <div style={{ paddingLeft: "2%" }}>{packing}</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <div style={{ display: "flex" }}>
                      <b>Shipped :</b>
                      <div style={{ paddingLeft: "2%" }}>{shipping}</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={4}>
                    <div style={{ display: "flex" }}>
                      <b>Delivered :</b>
                      <div style={{ paddingLeft: "2%" }}>{delivered}</div>
                    </div>
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          );
        })}
        <Dialog
          fullScreen
          open={this.state.openOrder}
          onClose={() => {
            this.setState({ openOrder: false });
          }}
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ openOrder: false });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                Orders
              </Typography>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <div style={{ paddingLeft: "1%", paddingRight: "1%" }}>{myorder}</div>
        </Dialog>
      </div>
    );
  }
}

export default Ordermaster;
