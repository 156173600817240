import React, { Component } from "react";
import {
  Grid,
  Radio,
  Button,
  Icon,
  Header,
  Form,
  Input,
  Loader,
} from "semantic-ui-react";
import $ from "jquery";
import server from "../../functions/server";
import "./address.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Grids from "@material-ui/core/Grid";
import UserId from "../../functions/userid";
import Network from "../../Utilis/Network";

class Viewaddress extends Component {
  state = {
    editAddress: 0,
    dialog: false,
    error: "",
    value: this.props.type,
    loading: false,
    errorDialog: false,
  };
  chooseAddress(id) {
    this.props.chooseAddress(id);
  }

  isSelected(selectedAddressId) {
    this.props.isSelectedAddress(
      selectedAddressId,
      this.props.name,
      this.props.type,
      this.props.phone,
      this.props.street,
      this.props.city,
      this.props.state,
      this.props.country,
      this.props.pincode
    );
  }

  editAddress(addressId, status) {
    this.setState({
      editAddress: addressId,
      dialog: true,
    });
  }

  deleteAddress(addressId) {
    let context = this;
    let userId = UserId();
    let payload = { addressId: addressId, userId: userId };
    context.setState({ loading: true });
    let url = server() + "/address/delete.php";

    Network.POST(
      url,
      payload,
      (data) => {
        context.setState({ loading: false });
        data = Number(data);
        if (data === 1) {
          context.props.deleteAddress(addressId);
        }
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  closeDialog() {
    this.setState({
      dialog: false,
    });
    this.props.deleteAddress("refresh");
  }

  handleChange = (e, { value }) => this.setState({ value, error: "" });

  clearError() {
    this.setState({ error: "" });
  }

  handleChange = (e, { value }) => this.setState({ value, error: "" });

  updateAddresss(addressId) {
    let context = this;
    let userId = UserId();
    let name = document.getElementById("name").value.trim();
    let phone = document.getElementById("mobile").value.trim();
    let pincode = document.getElementById("pincode").value.trim();
    let street = document.getElementById("street").value.trim();
    let city = document.getElementById("city").value.trim();
    let state = document.getElementById("state").value.trim();
    let country = document.getElementById("country").value.trim();
    let type = this.state.value;
    if (!name || name.length < 3) {
      context.setState({ error: "Name is invalid!!!" });
      return;
    }

    if (!phone || phone.length < 3) {
      context.setState({ error: "Enter your mobile number!!!" });
      return;
    }
    phone = parseInt(phone);

    if (!pincode || pincode.length <= 2) {
      context.setState({ error: "Pincode number is invalid!!!" });
      return;
    }

    if (!street || street.length < 3) {
      context.setState({ error: "Street name is invalid!!!" });
      return;
    }

    if (!city || city.length < 3) {
      context.setState({ error: "City name is invalid!!!" });
      return;
    }

    if (!state || state.length < 3) {
      context.setState({ error: "State name is invalid!!!" });
      return;
    }

    if (!country || country.length < 3) {
      context.setState({ error: "Country name is invalid!!!" });
      return;
    }

    if (type === undefined) {
      context.setState({ error: "Choose your delivery option!!!" });
      return;
    }

    let data1 = {
      userId: userId,
      addressId: addressId,
      name: name,
      phone: phone,
      pincode: pincode,
      street: street,
      city: city,
      state: state,
      country: country,
      type: type,
    };
    context.setState({ loading: true });
    let url = server() + "/address/edit.php";
    Network.POST(
      url,
      data1,
      (data) => {
        context.setState({ dialog: false, loading: false });
        data = Number(data);
        if (data === 1) {
          context.update(
            addressId,
            name,
            phone,
            pincode,
            street,
            city,
            state,
            country,
            type
          );
        }
      },
      (err) => {
        context.setState({ errorDialog: true });
      }
    );
  }

  update(addressId, name, phone, pincode, street, city, state, country, type) {
    let address = this.props.address;
    for (var i = 0; i < address.length; i++) {
      if (address[i].id === addressId) {
        address[i].name = name;
        address[i].phone = phone;
        address[i].pincode = pincode;
        address[i].street = street;
        address[i].city = city;
        address[i].state = state;
        address[i].country = country;
        address[i].type = type;
      }
    }
    this.props.updateAddress(address);
  }

  close() {
    this.setState({ dialog: false });
  }

  render() {
    let address = <div />;

    if (this.props.choose === "yes") {
      address = (
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            style={{ backgroundColor: "#CFD3D4", cursor: "pointer" }}
          >
            <Grid>
              <Grid.Column mobile={1} tablet={16} computer={1}>
                <Radio checked />
              </Grid.Column>
              <Grid.Column mobile={9} tablet={16} computer={12}>
                <b style={{ fontSize: "17px" }}>{this.props.name}</b>
                <p style={{ padding: "0.1%", fontSize: "12px", color: "red" }}>
                  {this.props.type}
                </p>
              </Grid.Column>
              <Grid.Column mobile={3} tablet={16} computer={3}>
                <b>{this.props.phone}</b>
              </Grid.Column>
              <Grid.Column mobile={1} tablet={16} computer={1} />
              <Grid.Column mobile={15} tablet={16} computer={16}>
                {this.props.street}, {this.props.city},{this.props.state},
                {this.props.country} - {this.props.pincode}
              </Grid.Column>
              <Grid.Column mobile={15} tablet={16} computer={5}>
                <Button
                  color="google plus"
                  style={{ height: "50px" }}
                  onClick={this.isSelected.bind(this, this.props.id)}
                  fluid
                >
                  <Icon name="shipping fast" />
                  Delivery Here
                </Button>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      );
    } else {
      address = (
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={16}
            style={{ cursor: "pointer" }}
          >
            <Grid>
              <Grid.Column
                mobile={1}
                tablet={16}
                computer={1}
                onClick={this.chooseAddress.bind(this, this.props.id)}
              >
                <Radio />
              </Grid.Column>
              <Grid.Column
                mobile={9}
                tablet={16}
                computer={12}
                onClick={this.chooseAddress.bind(this, this.props.id)}
              >
                <b style={{ fontSize: "17px" }}>{this.props.name}</b>
                <p style={{ padding: "0.1%", fontSize: "12px", color: "red" }}>
                  {this.props.type}
                </p>
              </Grid.Column>
              <Grid.Column
                mobile={3}
                tablet={16}
                computer={3}
                onClick={this.chooseAddress.bind(this, this.props.id)}
              >
                <b>{this.props.phone}</b>
              </Grid.Column>
              <Grid.Column mobile={1} tablet={16} computer={1} />
              <Grid.Column
                mobile={15}
                tablet={16}
                computer={11}
                onClick={this.chooseAddress.bind(this, this.props.id)}
              >
                {this.props.street}, {this.props.city},{this.props.state},
                {this.props.country} - {this.props.pincode}
              </Grid.Column>
              <Grid.Column mobile={15} tablet={16} computer={2}>
                <Header
                  size="small"
                  id="header"
                  onClick={this.editAddress.bind(this, this.props.id, "sendId")}
                >
                  Edit
                </Header>
              </Grid.Column>
              <Grid.Column mobile={15} tablet={16} computer={2}>
                <Header
                  size="small"
                  id="header"
                  onClick={this.deleteAddress.bind(this, this.props.id)}
                >
                  Delete
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      );
    }
    return (
      <div>
        {address}

        <Dialog
          open={this.state.dialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"EDIT ADDRESS"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grids container spacing={24}>
                <Grids item xs={12}>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <Input
                            id="name"
                            defaultValue={this.props.name}
                            placeholder="Name"
                            fluid
                            focus
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={8} tablet={8} computer={8}>
                          <Input
                            id="mobile"
                            defaultValue={this.props.phone}
                            placeholder="Mobile"
                            type="number"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Input
                            id="pincode"
                            defaultValue={this.props.pincode}
                            placeholder="Pincode"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Input
                            id="street"
                            defaultValue={this.props.street}
                            placeholder="Street"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Input
                            id="city"
                            defaultValue={this.props.city}
                            placeholder="City"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Input
                            id="state"
                            defaultValue={this.props.state}
                            placeholder="State"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Input
                            id="country"
                            defaultValue={this.props.country}
                            placeholder="Country"
                            focus
                            fluid
                            onChange={this.clearError.bind(this)}
                          />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <Form>
                            <Form.Field>
                              <Radio
                                label="Home(All days Delivery)"
                                name="radioGroup"
                                defaultChecked
                                value="home"
                                checked={this.state.value === "home"}
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Radio
                                label="Work(Week days Delivery)"
                                name="radioGroup"
                                value="work"
                                checked={this.state.value === "work"}
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Form>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={16}>
                          <Button
                            positive
                            fluid
                            onClick={this.updateAddresss.bind(
                              this,
                              this.props.id
                            )}
                          >
                            SAVE
                          </Button>
                          <br />
                          <Button
                            negative
                            fluid
                            onClick={this.close.bind(this)}
                          >
                            CANCEL
                          </Button>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={16}>
                          <center style={{ color: "red" }}>
                            {this.state.error}
                          </center>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                  </Grid>
                </Grids>
              </Grids>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Viewaddress;
