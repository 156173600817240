import React from "react";
import { Card, Icon, Button } from "semantic-ui-react";
import shop from "./shop.jpg";
import { Link } from "react-router-dom";

const style = {
  image: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
  },
  card: {
    textAlign: "left",
  },
  description: {
    maxHeight: "2.6em",
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
  },
};

export default class Shop extends React.Component {
  render() {
    let { shop } = this.props;
    let name = shop.shop_name;
    let description = shop.description;
    let image = shop.feature_image;
    let path = name.split(" ").join("-");
    let url = "/" + path;
    let count = shop.count;
    return (
      <div>
        {count === 0 ? (
          <div>
            <center>
              <Link to={url}>
                <Card style={style.card}>
                  {image ? (
                    <img style={style.image} src={image} alt="" />
                  ) : (
                    <img
                      style={style.image}
                      src={require("./shop.jpg")}
                      alt=""
                    />
                  )}
                  <Card.Content style={style.content}>
                    <Card.Header>{name}</Card.Header>
                    <Card.Meta>
                      <span className="date"> </span>
                    </Card.Meta>
                    <Card.Description style={style.description}>
                      {description}
                    </Card.Description>
                    <br />
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name="boxes" />
                      {shop.count + " Products"}
                    </a>
                  </Card.Content>
                </Card>
              </Link>
            </center>
          </div>
        ) : (
          <div>
            <center>
              <Link to={url}>
                <Card style={style.card}>
                  {image ? (
                    <img style={style.image} src={image} alt="" />
                  ) : (
                    <img
                      style={style.image}
                      src={require("./shop.jpg")}
                      alt=""
                    />
                  )}
                  <Card.Content style={style.content}>
                    <Card.Header>{name}</Card.Header>
                    <Card.Meta>
                      <span className="date"> </span>
                    </Card.Meta>
                    <Card.Description style={style.description}>
                      {description}
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <a>
                      <Icon name="boxes" />
                      {shop.count + " Products"}
                    </a>
                  </Card.Content>
                </Card>
              </Link>
            </center>
          </div>
        )}
      </div>
    );
  }
}
