function cartss() {
  try {
    var nameEQ = "cart" + "=";
    var ca = decodeURIComponent(document.cookie).split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0){
          let cook = c.substring(nameEQ.length,c.length);
          return cook;
        }
    }
    return 0;
  } catch(e) {}
  return 0;
}

export default cartss;
