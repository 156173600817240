import React, { Component } from "react";
import { Card, Grid } from "semantic-ui-react";

class Detailprice extends Component {
  taxPrice(tax, value) {
    try {
      tax = Number(tax);
      value = Number(value);
      value = value + (value * tax) / 100;
    } catch (e) {
      value = 0;
    }
    value = value.toFixed(2);
    return value;
  }

  render() {
    let tax = this.props.tax;
    let prize = this.props.prize;
    let total = this.props.quantity * this.props.prize;
    prize = this.taxPrice(tax, prize);
    total = this.taxPrice(tax, total);
    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {this.props.product_name}
              </Grid.Column>
              <Grid.Column mobile={7} tablet={7} computer={7}>
                Price
                <br />
                Quantity
              </Grid.Column>
              <Grid.Column mobile={1} tablet={1} computer={2}>
                =<br />=
              </Grid.Column>
              <Grid.Column mobile={7} tablet={7} computer={7}>
                <div style={{ float: "right" }}>
                  {prize}
                  <br />x {this.props.quantity}
                </div>
              </Grid.Column>
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={8}
                style={{ color: "green" }}
              >
                <b>Total</b>
              </Grid.Column>
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={8}
                style={{ color: "green" }}
              >
                <div style={{ float: "right" }}>
                  <b>{total}</b>
                </div>
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

export default Detailprice;
