import React from "react";
import domain from "../functions/domain";
import Title from "./title";
import { Grid } from "semantic-ui-react";
import Shop from "../home/shop";
import Network from "../Utilis/Network";
import server from "../functions/server";


export default class AllShops extends React.Component {
  state = {
    allShop: [],
    search: "",
  };

  getCategory() {
    let category = window.location.href;
    category = category
      .split(domain())[1]
      .split("/")[1]
      .split("-")
      .join(" ");
    return category;
  }

  componentDidMount() {
    this.getShopByCategory(this.state.search);
  }

  getShopByCategory(search) {
    let context = this;
    let category = this.getCategory();
    const payload = { category: category, search: search };
    let url = server() + "/shop/getshopbycategorys.php";
    Network.POST(
      url,
      payload,
      (data) => {
        console.log(data);
        context.setState({ allShop: data, search });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  processShop() {
    return this.state.allShop.map((x) => {
      return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Shop shop={x} />
        </Grid.Column>
      );
    });
  }

  search(search) {
    this.getShopByCategory(search);
  }

  render() {
    let context = this;
    let title = this.getCategory();
    return (
      <div
        style={{
          paddingBottom: 30,
          paddingTop: 90,
          paddingRight: "2%",
          paddingLeft: "2%",
        }}
      >
        <Title title={title} search={this.search.bind(this)} />
        <Grid style={{ paddingRight: "-5%" }}>{context.processShop()}</Grid>
      </div>
    );
  }
}
