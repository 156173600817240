import React, { Component } from "react";
import Login from "./login";
import Address from "./address/";
import Summary from "./summary/summary";
import Payment from "./payment";
import Cart from "./cart";
import Detailprice from "./detailprice";
import { Icon, Step, Grid, Card, Button, Header } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import server from "../functions/server";
import "./order.css";
import Title from "./title";
import UserId from "../functions/userid";
import Network from "../Utilis/Network";

class Order extends Component {
  state = {
    step: 0,
    addressId: 0,
    address: [],
    display: "",
    hidden: "none",
    cart: [],
    total: 0,
    item: 0,
    realPrice: 0,
    status: "",
    color0: "#DDDBD9",
    pickup: "",
    shops: "",
    homePage: false,
  };

  componentDidMount() {
    let context = this;
    context.setState({ loading: false });
    let userId = UserId();
    let urls = new URL(window.location.href);
    let shopId = urls.searchParams.get("shop");
    let shopname = urls.searchParams.get("shopname");
    let url;
    let payload;
    if (shopId) {
      url = server() + "/cart/get.php";
      payload = { userId: userId, shopId: shopId };
    } else {
      url = server() + "/cart/getbyname.php";
      try {
        shopname = shopname.split("-").join(" ");
      } catch (e) {
      } finally {
      }
      payload = { userId: userId, shopName: shopname };
    }

    Network.POST(
      url,
      payload,
      (data) => {
        console.log(data);
        context.setState({ loading: false });
        if (data.length === 0) {
          context.setState({
            homePage: true,
          });
        }
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  refereshCart(cart) {
    let prize = 0;
    let realPrice = 0;
    cart.map((c) => {
      let price = c.prize;
      let quantity = c.quantity;
      let offerends = c.offerends;
      let discount = c.discount;
      let tax = c.tax;
      discount = Number(discount);
      tax = Number(tax);
      price = Number(price);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        price = price * quantity;
        price = price + (price * tax) / 100;
        prize = prize + price;
      } else {
        if (offerends <= quantity) {
          prize =
            prize + (discount * offerends + (discount * offerends * tax) / 100);
          prize =
            prize +
            (price * (quantity - offerends) +
              (price * (quantity - offerends) * tax) / 100);
        } else {
          prize =
            prize + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
    });
    let percentage = prize / 100;
    percentage = percentage / 2;
    prize = prize + percentage;
    this.setState({ cart: cart, total: prize, item: cart.length });
  }

  gotostep(step, addressId, address, status, pickup, shop) {
    if (step === 1 || step === 0) {
      this.setState({
        display: "",
        hidden: "none",
        step: step,
        addressId: addressId,
        address: address,
      });
    }
    if (step === 2) {
      if (shop) {
        this.setState({
          display: "none",
          hidden: "",
          pickup: pickup.getTime(),
          shopAddress: address,
        });
      } else {
        this.setState({ display: "none", hidden: "", shopAddress: pickup });
      }
    }
    this.setState({
      step: step,
      addressId: addressId,
      address: address,
      status: status,
      shops: shop,
    });

    if (step === 0) {
      this.setState({
        color0: "#DDDBD9",
        color1: "",
        color2: "",
        color3: "",
      });
    }
    if (step === 1) {
      this.setState({
        color0: "",
        color1: "#DDDBD9",
        color2: "",
        color3: "",
      });
    }
    if (step === 2) {
      this.setState({
        color0: "",
        color1: "",
        color2: "#DDDBD9",
        color3: "",
      });
    }
    if (step === 3) {
      this.setState({
        color0: "",
        color1: "",
        color2: "",
        color3: "#DDDBD9",
      });
    }
  }

  detailsPrice() {
    let detailsPrice = [];
    this.state.cart.map((z) => {
      detailsPrice.push(
        <Detailprice
          tax={z.tax}
          cart={this.state.cart}
          id={z.id}
          quantity={z.quantity}
          product_name={z.product_name}
          prize={z.prize}
        />
      );
    });
    return detailsPrice;
  }

  render() {
    let detailsPrice = this.detailsPrice();
    let x = <div />;
    if (this.state.step === 0) {
      return <Login gotostep={this.gotostep.bind(this)} />;
    }
    if (this.state.step === 1) {
      x = <Address gotostep={this.gotostep.bind(this)} />;
    }
    if (this.state.step === 2 && this.state.addressId > 0) {
      x = (
        <Summary
          status={this.state.status}
          total={this.state.total}
          addressId={this.state.addressId}
          address={this.state.address}
          item={this.state.item}
          cart={this.state.cart}
          refereshCart={this.refereshCart.bind(this)}
          gotostep={this.gotostep.bind(this)}
          pickup={this.state.pickup}
          shops={this.state.shops}
          shopAddress={this.state.shopAddress}
        />
      );
    } else {
      x = <Address gotostep={this.gotostep.bind(this)} />;
    }
    if (this.state.step === 3) {
      x = <Payment />;
    }
    let drandTotal = this.state.total;
    try {
      drandTotal = Number(drandTotal).toFixed(2);
    } catch (e) {
      drandTotal = 0;
    }
    return (
      <div id="homepadding">
        {this.state.homePage ? (
          <Grid inverted>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title title="Order" />
              <br />
              <br />
              <br />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <center>
                <Card id="login">
                  <Header
                    as="h2"
                    icon
                    textAlign="center"
                    style={{ marginTop: 50, color: "#9EB6CE" }}
                  >
                    <Header.Content>
                      There is no products in your cart
                    </Header.Content>
                  </Header>
                  <div>Go back to Home Page</div>
                  <br />
                  <Grid>
                    <Grid.Column mobile={8} tablet={8} computer={16}>
                      <Button
                        variant="contained"
                        color="primary"
                        id="buttonClicked"
                        fluid
                        onClick={() => (window.location.href = "/")}
                      >
                        Back
                      </Button>
                    </Grid.Column>
                  </Grid>
                  <br />
                </Card>
              </center>
            </Grid.Column>
          </Grid>
        ) : (
          <Grid inverted>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Title title="Order" />
              <br />
              <br />
              <br />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={5}
              style={{ display: this.state.display }}
              id="first"
            >
              <Cart refereshCart={this.refereshCart.bind(this)} />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={5}
              style={{ display: this.state.hidden }}
              id="first"
            >
              <Card fluid>
                <Card.Content style={{ backgroundColor: "#74D4F4" }}>
                  <Card.Header>Grand Total</Card.Header>
                </Card.Content>
                <Card.Content>{detailsPrice}</Card.Content>
                <Card.Content style={{ backgroundColor: "green" }}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <h3 style={{ color: "white", fontFamily: "Tahoma" }}>
                        GRAND TOTAL
                      </h3>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={7}>
                      <div style={{ float: "right", color: "white" }}>
                        <h3>Rs: {drandTotal}</h3>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={11}>
              <Step.Group id="menu" widths={3} unstackable>
                <Step
                  onClick={this.gotostep.bind(this, 0)}
                  style={{ backgroundColor: this.state.color0 }}
                >
                  <Step.Content id="padding">
                    <center>
                      <Icon name="user" id="iconSize" />
                      <Step.Title id="textSize">LOGIN USER</Step.Title>
                    </center>
                  </Step.Content>
                </Step>

                <Step
                  onClick={this.gotostep.bind(this, 1)}
                  style={{ backgroundColor: this.state.color1 }}
                >
                  <Step.Content id="padding">
                    <center>
                      <Icon name="shipping fast" id="iconSize" />
                      <Step.Title id="textSize">DELIVERY</Step.Title>
                    </center>
                  </Step.Content>
                </Step>

                <Step
                  onClick={this.gotostep.bind(this, 2)}
                  style={{ backgroundColor: this.state.color2 }}
                >
                  <Step.Content id="padding">
                    <center>
                      <Icon name="info" id="iconSize" />
                      <Step.Title id="textSize">ORDER SUMMARY</Step.Title>
                    </center>
                  </Step.Content>
                </Step>

                <Step
                  onClick={this.gotostep.bind(this, 3)}
                  style={{ display: "none" }}
                >
                  <Step.Content id="padding">
                    <center>
                      <Icon name="rupee sign" id="iconSize" />
                      <Step.Title id="textSize">PAYMENT OPTION</Step.Title>
                    </center>
                  </Step.Content>
                </Step>
              </Step.Group>
              {x}
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
  }
}

export default Order;
// <Summary gotostep={this.gotostep.bind(this)} addressId={this.state.addressId} address={this.state.address}/>
