import React, { Component } from 'react';
import { Button, Grid, Input, Radio, Form, Loader } from 'semantic-ui-react';
import $ from "jquery";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import UserId from '../../functions/userid';
import Network from '../../Utilis/Network';
import server from '../../functions/server';

class Addaddress extends Component {
  state = {
    loading: false,
    errorDialog: false
  }

  clearError () { this.setState ({ error: ''})  }

  handleChange = (e, { value }) => this.setState({ value, error: '' })


  addAddress()
  {
    let context = this;
    let userId = UserId();
    let name = document.getElementById('name').value.trim();
    let phone = document.getElementById('mobile').value.trim();
    let pincode = document.getElementById('pincode').value.trim();
    let street = document.getElementById('street').value.trim();
    let city = document.getElementById('city').value.trim();
    let state = document.getElementById('state').value.trim();
    let country = document.getElementById('country').value.trim();
    let type = this.state.value;
    if (!name || name.length < 3) {
      context.setState({
        error: 'Name is invalid!!!',
      });
      return;
    }

    if (!phone || phone.length < 3) {
      context.setState({
        error: 'Enter your mobile number!!!',
      });
      return;
    }
    phone = parseInt(phone);

    if (!pincode || pincode.length <= 2) {
      context.setState({
        error: 'Pincode number is invalid!!!',
      });
      return;
    }

    if (!street || street.length < 3) {
      context.setState({
        error: 'Street name is invalid!!!',
      });
      return;
    }

    if (!city || city.length < 3) {
      context.setState({
        error: 'City name is invalid!!!',
      });
      return;
    }

    if (!state || state.length < 3) {
      context.setState({
        error: 'State name is invalid!!!',
      });
      return;
    }

    if (!country || country.length < 3) {
      context.setState({
        error: 'Country name is invalid!!!',
      });
      return;
    }

    if (type === undefined) {
      context.setState({
        error: 'Choose your delivery option!!!',
      });
      return;
    }
    let payload = { 'userId' : userId, 'name' : name, 'phone' : phone, 'pincode' : pincode, 'street' : street, 'city' : city, 'state' : state, 'country' : country, 'type' : type };
    context.setState({ loading: true });
    let url = server() + '/address/add.php';
    Network.POST(url,payload,(data)=>
    {
      console.log(data);
      context.setState({ loading: false })
      let result = JSON.parse(data);
      let id = result[0].id;
      payload["id"] = id;
      context.props.addAddress(payload, false, id);
    },(err)=>
    {
      context.setState({ errorDialog: true, loading: false });
    })
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  render() {
    return (
      <div style={{padding: '2%'}}>
        <Grid>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Input id='name' placeholder='Name' fluid focus onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={8} computer={8}>
            <Input id='mobile' placeholder='Mobile' type='number' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Input id='pincode' placeholder='Pincode' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Input id='street' placeholder='Address' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Input id='city' placeholder='City' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Input id='state' placeholder='State' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Input id='country' placeholder='Country' focus fluid onChange={this.clearError.bind(this)} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
          <Form>
            <Form.Field>
              <Radio label='Home(All days Delivery)' name='radioGroup' value='Home' checked={this.state.value === 'Home'} onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <Radio label='Office(Week days Delivery)' name='radioGroup' value='Office' checked={this.state.value === 'Office'} onChange={this.handleChange} />
            </Form.Field>
          </Form>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={16}>
            <Button positive fluid onClick={this.addAddress.bind(this)}>SAVE</Button>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={16}>
            <center style={{color: 'red'}}>{this.state.error}</center>
          </Grid.Column>
        </Grid>
        <Dialog open={this.state.loading} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">{"LOADING..."}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline='centered' />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.errorDialog} aria-labelledby="responsive-dialog-title">
          <DialogContent>
            <DialogContentText>
              Try again!!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Addaddress;
