import React, { Component } from "react";
import Viewcart from "../cart/viewcart";
import $ from "jquery";
import server from "../../functions/server";
import domain from "../../functions/domain";
import { Card, Button, Grid, Loader } from "semantic-ui-react";
import UserId from "../../functions/userid";
import UserMobile from "../../functions/usermobilenumber";
import UserName from "../../functions/username";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import cartCount from "../../functions/cart";
import Network from "../../Utilis/Network";

class Summary extends Component {
  state = {
    conformedOrder: false,
    pickuptime: "",
  };

  orderCart() {
    let cart = [];
    this.props.cart.map((z) => {
      cart.push(
        <Viewcart
          updateChart={this.updateChart.bind(this)}
          cart={this.props.cart}
          removeItem={this.removeItem.bind(this)}
          id={z.id}
          cartId={z.cartId}
          quantity={z.quantity}
          product_name={z.product_name}
          prize={z.prize}
          no_stock={z.no_stock}
          discount={z.discount}
          offerends={z.offerends}
          feature_image={z.feature_image}
          tax={z.tax}
        />
      );
    });
    return cart;
  }

  removeItem(id) {
    let cart = this.props.cart;
    let dele = [];
    cart.map((del) => {
      if (del.cartId === id) {
        return;
      } else {
        dele.push(del);
      }
    });
    let prize = 0;
    dele.map((c) => {
      let price = c.prize;
      let quantity = c.quantity;
      let offerends = c.offerends;
      let discount = c.discount;
      let tax = c.tax;
      discount = Number(discount);
      tax = Number(tax);
      price = Number(price);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        price = price * quantity;
        price = price + (price * tax) / 100;
        prize = prize + price;
      } else {
        if (offerends <= quantity) {
          prize =
            prize + (discount * offerends + (discount * offerends * tax) / 100);
          prize =
            prize +
            (price * (quantity - offerends) +
              (price * (quantity - offerends) * tax) / 100);
        } else {
          prize =
            prize + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
    });
    this.setState({ cart: dele, total: prize, item: dele.length });
    this.props.refereshCart(dele);
  }

  updateChart(updateChart) {
    let prize = 0;
    let realPrice = 0;
    updateChart.map((c) => {
      let price = c.prize;
      let quantity = c.quantity;
      let offerends = c.offerends;
      let discount = c.discount;
      let tax = c.tax;
      discount = Number(discount);
      tax = Number(tax);
      price = Number(price);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        price = price * quantity;
        price = price + (price * tax) / 100;
        prize = prize + price;
      } else {
        if (offerends <= quantity) {
          prize =
            prize + (discount * offerends + (discount * offerends * tax) / 100);
          prize =
            prize +
            (price * (quantity - offerends) +
              (price * (quantity - offerends) * tax) / 100);
        } else {
          prize =
            prize + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
    });
    this.setState({
      cart: updateChart,
      total: prize,
      item: updateChart.length,
    });
    this.props.refereshCart(updateChart);
  }

  finish() {
    if (this.props.cart.length <= 0) {
      alert("Please Select products!!!");
      return;
    }
    let context = this;
    let shop = this.props.shops;
    let milli = this.props.pickup;
    var date = new Date(milli);
    var years = { year: "numeric" };
    var months = { month: "numeric" };
    var dates = { day: "numeric" };
    let hours = date.getHours();
    hours = hours.toString();
    let min = date.getMinutes();
    min = min.toString();
    let sec = date.getSeconds();
    sec = sec.toString();
    var year = date.toLocaleDateString("en", years);
    var month = date.toLocaleDateString("en", months);
    var date = date.toLocaleDateString("en", dates);
    if (month.length === 1) {
      month = 0 + month;
    }
    if (date.length === 1) {
      date = 0 + date;
    }
    if (hours.length === 1) {
      hours = 0 + hours;
    }
    if (min.length === 1) {
      min = 0 + min;
    }
    if (sec.length === 1) {
      sec = 0 + sec;
    }
    let fulldate =
      year + "-" + month + "-" + date + " " + hours + ":" + min + ":" + sec;
    let userId = UserId();
    let userName = UserName();
    let userMobile = UserMobile();
    if (this.props.status !== "myself") {
      let address = this.props.address;
      address.map((x) => {
        if (x.id === this.props.addressId) {
          userMobile = x.phone;
        }
      });
    }
    let url = new URL(window.location.href);
    let shopId = url.searchParams.get("shop");
    let shopname = url.searchParams.get("shopname");
    let data1;
    let urls;
    let carts = this.calculate(this.props.cart);
    let deliveryAmount = this.deliveryeCharge(this.props.total);
    try {
      deliveryAmount = deliveryAmount.split(" ")[1];
    } catch (e) {
      deliveryAmount = 0;
    }
    if (!deliveryAmount) {
      deliveryAmount = 0;
    }
    if (shopId) {
      data1 = {
        cart: carts,
        addressID: this.props.addressId,
        userId: userId,
        amount: this.props.total,
        status: this.props.status,
        pickuptime: fulldate,
        userName: userName,
        userMobile: userMobile,
        shopId: shopId,
        deliveryAmount: deliveryAmount,
      };
      urls = server() + "/order/payandconformbyshopid.php";
    } else {
      try {
        shopname = shopname.split("-").join(" ");
      } catch (e) {}
      data1 = {
        cart: carts,
        addressID: this.props.addressId,
        userId: userId,
        amount: this.props.total,
        status: this.props.status,
        pickuptime: fulldate,
        userName: userName,
        userMobile: userMobile,
        shopName: shopname,
        deliveryAmount: deliveryAmount,
      };
      urls = server() + "/order/payandconform.php";
    }
    this.setState({ loading: true });

    Network.POST(
      urls,
      data1,
      (data) => {
        context.setState({ loading: false });
        console.log(data);
        if (data === 1) {
          let len = context.props.cart.length;
          let empty = [];
          document.cookie = "cart =" + empty;
          context.gotopayment();
        }
      },
      (err) => {
        context.setState({ loading: false });
      }
    );
  }

  calculate(cart) {
    let shopAddress = this.props.shopAddress;
    let shopMobile;
    shopAddress.map((c) => {
      shopMobile = c.phone;
    });
    cart.map((c) => {
      let tax = c.tax;
      let discount = c.discount;
      let prize = c.prize;
      let price = 0;
      let offerends = c.offerends;
      let quantity = c.quantity;
      discount = Number(discount);
      tax = Number(tax);
      prize = Number(prize);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        prize = prize * quantity;
        prize = prize + (prize * tax) / 100;
        price = price + prize;
      } else {
        if (offerends <= quantity) {
          price =
            price + (discount * offerends + (discount * offerends * tax) / 100);
          price =
            price +
            (prize * (quantity - offerends) +
              (prize * (quantity - offerends) * tax) / 100);
        } else {
          price =
            price + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
      c.tot_price = price + (price * 0.5) / 100;
      c.shopMobile = shopMobile;
    });
    return cart;
  }

  addressChange() {
    this.props.gotostep(1);
  }

  gotopayment() {
    window.location.href = domain() + "payment";
  }

  deliveryeCharge(amount) {
    if (this.props.status === "myself") {
      let shopAddress = this.props.address;
      let deliverAmount = 0;
      let deliverMaxAmount = 0;
      shopAddress.map((f) => {
        deliverAmount = f.delivery_charge;
        deliverMaxAmount = f.delivery_maximum_amount;
      });
      try {
        deliverAmount = Number(deliverAmount);
        deliverMaxAmount = Number(deliverMaxAmount);
      } catch (e) {}
      if (deliverMaxAmount < amount) {
        amount = "Free";
      } else {
        deliverAmount = Number(deliverAmount).toFixed(2);
        amount = "Rs " + deliverAmount;
      }
      return amount;
    } else {
      let shopAddress = this.props.shopAddress;
      let deliverAmount = 0;
      let deliverMaxAmount = 0;
      shopAddress.map((f) => {
        deliverAmount = f.delivery_charge;
        deliverMaxAmount = f.delivery_maximum_amount;
      });
      try {
        deliverAmount = Number(deliverAmount);
        deliverMaxAmount = Number(deliverMaxAmount);
      } catch (e) {}
      if (deliverMaxAmount < amount) {
        amount = "Free";
      } else {
        amount = "Rs " + deliverAmount;
      }
      return amount;
    }
  }

  getCharge(amount) {
    let deliveryAmount = this.deliveryeCharge(amount);
    if (deliveryAmount === "Free") {
      deliveryAmount = 0;
    } else {
      deliveryAmount = deliveryAmount.split("Rs ")[1];
      deliveryAmount = Number(deliveryAmount);
    }
    if (this.props.status === "myself") {
      let shopAddress = this.props.address;
      let deliverAmount = 0;
      let deliverMaxAmount = 0;
      shopAddress.map((f) => {
        deliverAmount = f.delivery_charge;
        deliverMaxAmount = f.delivery_maximum_amount;
      });
      try {
        deliverAmount = Number(deliverAmount);
        deliverMaxAmount = Number(deliverMaxAmount);
      } catch (e) {}
      if (deliverMaxAmount < amount) {
        amount = amount + deliverAmount;
      }
      amount = Number(amount).toFixed(2);
      return "Rs " + amount;
    } else {
      let shopAddress = this.props.shopAddress;
      let deliverAmount = 0;
      let deliverMaxAmount = 0;
      shopAddress.map((f) => {
        deliverAmount = f.delivery_charge;
        deliverMaxAmount = f.delivery_maximum_amount;
      });
      try {
        deliverAmount = Number(deliverAmount);
        deliverMaxAmount = Number(deliverMaxAmount);
      } catch (e) {}
      if (deliverMaxAmount < amount) {
        amount = amount + deliverAmount;
      }
      amount = Number(deliveryAmount) + Number(amount);
      amount = Number(amount).toFixed(2);
      return "Rs " + amount;
    }
  }

  gettime() {
    let context = this;
    let milli = this.props.pickup;
    var date = new Date(milli);
    var years = { year: "numeric" };
    var months = { month: "numeric" };
    var dates = { day: "numeric" };
    let hours = date.getHours();
    hours = hours.toString();
    let min = date.getMinutes();
    min = min.toString();
    let sec = date.getSeconds();
    sec = sec.toString();
    var year = date.toLocaleDateString("en", years);
    var month = date.toLocaleDateString("en", months);
    var date = date.toLocaleDateString("en", dates);
    if (month.length === 1) {
      month = 0 + month;
    }
    if (date.length === 1) {
      date = 0 + date;
    }
    if (hours.length === 1) {
      hours = 0 + hours;
    }
    if (min.length === 1) {
      min = 0 + min;
    }
    if (sec.length === 1) {
      sec = 0 + sec;
    }
    let fulldate =
      year + "-" + month + "-" + date + " " + hours + ":" + min + ":" + sec;
    return fulldate;
  }

  render() {
    let date = this.gettime();
    let cart = this.orderCart();
    let address = <div />;
    if (this.props.status === "myself") {
      address = this.props.address.map((z) => {
        if (z.id === this.props.addressId) {
          return (
            <div>
              <Grid style={{ backgroundColor: "#e2e5e6" }}>
                <Grid.Column mobile={10} tablet={10} computer={11}>
                  <h4>Shop address</h4>
                </Grid.Column>
                <Grid.Column mobile={6} tablet={6} computer={5}>
                  <Button
                    basic
                    color="blue"
                    onClick={this.addressChange.bind(this)}
                  >
                    Change
                  </Button>
                </Grid.Column>
                <b style={{ color: "#28a8d6", fontSize: "18px" }}>
                  {z.shop_name}
                </b>
                <Grid.Column mobile={16} tablet={16} computer={1} />
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <b>{"Pickup time : " + date}</b>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={15}>
                  {z.address},<br />
                  {z.city},<br />
                  {z.state}, {z.country} - {z.pincode}
                  <br />
                  <br />
                  <b>Phone no : {z.phone}</b>
                  <br />
                  <br />
                  <b>Website: {z.website}</b>
                  <br />
                </Grid.Column>
              </Grid>
            </div>
          );
        }
      });
    } else {
      address = this.props.address.map((z) => {
        if (z.id === this.props.addressId) {
          return (
            <Grid style={{ backgroundColor: "#e2e5e6" }}>
              <Grid.Column mobile={10} tablet={10} computer={11}>
                <b>Delivery address</b>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={6} computer={5}>
                <Button
                  basic
                  color="blue"
                  onClick={this.addressChange.bind(this)}
                >
                  Change
                </Button>
              </Grid.Column>
              <b style={{ color: "#28a8d6", fontSize: "18px" }}>{z.name}</b>
              <Grid.Column mobile={16} tablet={16} computer={1} />
              <Grid.Column mobile={16} tablet={16} computer={15}>
                {z.street},&nbsp;&nbsp;
                {z.city}
                <br />
                {z.state},&nbsp;&nbsp;
                {z.country} - {z.pincode}
                <br />
                <br />
                <b>Phone no : {z.phone}</b>
                <br />
                <br />
              </Grid.Column>
            </Grid>
          );
        }
      });
    }
    let total = this.props.total;
    try {
      total = Number(total).toFixed(2);
    } catch (e) {}
    return (
      <div style={{ padding: "0.5%" }}>
        <Card fluid>
          <Card.Content style={{ backgroundColor: "#74D4F4" }}>
            <Card.Header>My Cart</Card.Header>
          </Card.Content>
          <Card.Content>{cart}</Card.Content>
          <Card.Content>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h4>ORDER SUMMARY</h4>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <b>Items</b>
                    <br />
                    <b>Price</b>
                    <br />
                    <b>
                      Delivery Charge
                      <br />
                    </b>
                    <b>Total</b>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <b style={{ float: "right" }}>
                      {this.props.item}
                      <br />
                      Rs: {total}
                      <br />
                      {this.deliveryeCharge(total)}
                      <br />
                      {this.getCharge(total)}
                    </b>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {address}
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
        <center>
          <Button
            positive
            style={{ height: "70px", width: "150px" }}
            onClick={this.finish.bind(this)}
          >
            Continue
          </Button>
        </center>
        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default Summary;
