import React, { PureComponent } from "react";
import {
  Card,
  Button,
  Icon,
  Grid,
  Header,
  Loader,
  Input,
  Checkbox,
} from "semantic-ui-react";
import $ from "jquery";
import server from "../../functions/server";
import Addaddress from "./addaddress";
import Viewaddress from "./view";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import UserId from "../../functions/userid";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "material-ui-pickers";
import Network from "../../Utilis/Network";

class Address extends PureComponent {
  state = {
    isAddressAdd: false,
    error: "",
    address: [],
    shopAddress: [],
    shopId: 0,
    selectedAddress: undefined,
    loading: false,
    errorDialog: false,
    category: "pickup",
    shopName: "",
    description: "",
    gettime: false,
    selectedDate: new Date(),
  };

  componentDidMount() {
    this.getLocation();
    this.selfBuy();
  }

  getLocation() {
    let latitude, longitude;
    navigator.geolocation.getCurrentPosition((location) => {
      this.getAddress(location.coords.latitude, location.coords.longitude);
    });
  }

  stringSplit(value) {
    try {
      value = value.replace("-", " ");
    } catch (e) {}
    return value;
  }

  getAddress(latitude, longitude) {
    let context = this;
    let url = new URL(window.location.href);
    let shopName = url.searchParams.get("shopname");
    shopName = this.stringSplit(shopName);
    context.setState({
      loading: true,
      visiableDeliveryDetails: true,
      category: "delivery",
    });
    let payload = {
      userId: UserId(),
      shopName: shopName,
      latitude: latitude,
      longitude: longitude,
    };
    let url1 = server() + "/address/getbyshopName.php";

    Network.POST(
      url1,
      payload,
      (data) => {
        let devliveryavilable;
        data.map((c) => {
          devliveryavilable = c.devliveryavilable;
        });
        if (devliveryavilable === 1) {
          context.setState({
            address: data,
            loading: false,
            devliveryavilable: devliveryavilable,
          });
        } else {
          context.setState({
            address: [],
            loading: false,
            devliveryavilable: devliveryavilable,
            category: "self",
          });
        }
      },
      (err) => {
        context.setState({
          errorDialog: true,
          loading: false,
          devliveryavilable: 0,
        });
      }
    );
  }

  addAddress(newAddress, closeAddFields, id) {
    let newAddresslist = this.state.address.concat(newAddress);
    this.setState({ isAddressAdd: closeAddFields, address: newAddresslist });
  }

  chooseAddress(id) {
    this.setState({ selectedAddress: id });
  }

  isSelectedAddress(id) {
    this.props.gotostep(
      2,
      id,
      this.state.address,
      this.state.selectedAddress,
      this.state.shopAddress
    );
  }

  deleteAddress(addressId) {
    let address = this.state.address;
    let dele = [];
    address.map((del) => {
      if (del.id === addressId) {
        return;
      } else {
        dele.push(del);
      }
    });
    this.setState({ address: dele });
  }

  updateAddress(address) {
    this.setState({ address: address });
  }

  selfBuy() {
    let url = new URL(window.location.href);
    let shopId = url.searchParams.get("shop");
    let shopName = url.searchParams.get("shopname");
    let userId = UserId();
    let context = this;
    let urls;
    let payload;
    if (shopName) {
      shopName = shopName.replace(/-/g, " ");
      payload = { shopName: shopName, userId: userId };
      urls = server() + "/address/getshopaddressbyname.php";
    } else {
      payload = { shopId: shopId, userId: userId };
      urls = server() + "/address/getshopaddress.php";
    }

    context.setState({ loading: true });
    Network.POST(
      urls,
      payload,
      (data) => {
        context.setState({ loading: false });
        try {
          let shopId, category, shopName, description, phone;
          let shopAddress = data;
          for (let i = 0; i < shopAddress.length; i++) {
            shopId = shopAddress[i].id;
            shopId = Number(shopId);
            category = shopAddress[i].category;
            shopName = shopAddress[i].shop_name;
            description = shopAddress[i].description;
            phone = shopAddress[i].phone;
          }
          context.setState({
            shopAddress: data,
            shopId: shopId,
            loading: false,
            shopphone: phone,
            shopName: shopName,
            description: description,
          });
        } catch (e) {}
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  selfBuy1() {
    this.props.gotostep(
      2,
      this.state.shopId,
      this.state.shopAddress,
      "myself",
      this.state.selectedDate,
      this.state.shopName
    );
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  getToday() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  checkBoxHandle = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  checkBoxHandle1 = (name) => (event) => {
    if (this.state.devliveryavilable == 0) {
      alert("Delivery Not available!!!!");
      return;
    }
    this.setState({ [name]: "delivery" });
  };

  render() {
    let maxDate = this.getToday();
    let x = <div />;
    if (this.state.isAddressAdd === true) {
      x = <Addaddress addAddress={this.addAddress.bind(this)} />;
    }
    let address = <div />;
    address = this.state.address.map((z) => {
      if (this.state.selectedAddress === z.id) {
        return (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Viewaddress
              isSelectedAddress={this.isSelectedAddress.bind(this)}
              choose="yes"
              id={z.id}
              type={z.type}
              country={z.country}
              state={z.state}
              city={z.city}
              street={z.street}
              pincode={z.pincode}
              phone={z.phone}
              name={z.name}
            />
          </Grid.Column>
        );
      } else {
        return (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Viewaddress
              updateAddress={this.updateAddress.bind(this)}
              address={this.state.address}
              deleteAddress={this.deleteAddress.bind(this)}
              chooseAddress={this.chooseAddress.bind(this)}
              id={z.id}
              type={z.type}
              country={z.country}
              state={z.state}
              city={z.city}
              street={z.street}
              pincode={z.pincode}
              phone={z.phone}
              name={z.name}
            />
          </Grid.Column>
        );
      }
    });
    const { selectedDate } = this.state;
    return (
      <div>
        <Grid style={{ paddingRight: "0.5%" }}>
          <Grid.Column mobile={16} tablet={8} computer={16}>
            {this.state.category === "delivery" ? (
              <Card fluid>
                <Card.Content style={{ backgroundColor: "#74D4F4" }}>
                  <Card.Header>
                    <Grid>
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <div align="left">Address</div>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <div align="right">
                          <Button
                            basic
                            color="black"
                            onClick={this.checkBoxHandle("category")}
                          >
                            Self Pickup
                          </Button>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  <Grid>{address}</Grid>
                </Card.Content>
                {x}
                <Card.Content>
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => this.setState({ isAddressAdd: true })}
                  >
                    <Icon name="plus" />
                    Add address
                  </Button>
                </Card.Content>
              </Card>
            ) : (
              <Card fluid>
                <Card.Content>
                  <Grid style={{ paddingTop: "2% " }}>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <div id="hotel">
                        <Header
                          style={{
                            fontSize: 30,
                            color: "white",
                            paddingRight: "2%",
                          }}
                        >
                          <Grid>
                            <Grid.Column mobile={16} tablet={16} computer={11}>
                              <div align="left">
                                &nbsp;{this.state.shopName}
                              </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={5}>
                              <div align="right" style={{ color: "white" }}>
                                {this.state.visiableDeliveryDetails ? (
                                  <Button
                                    basic
                                    color="black"
                                    onClick={this.checkBoxHandle1("category")}
                                  >
                                    Home Delivery
                                  </Button>
                                ) : (
                                  " "
                                )}
                              </div>
                            </Grid.Column>
                          </Grid>
                        </Header>
                        <div style={{ paddingTop: "42%" }} />
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      {this.state.description}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <b style={{ color: "#06B0F9" }}>
                        Select Pickup Date and Time
                      </b>
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          value={selectedDate}
                          onChange={this.handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Button
                        fluid
                        id="button"
                        onClick={this.selfBuy1.bind(this)}
                      >
                        <b>Pick Up</b>
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
        </Grid>
        <br />

        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Address;
