import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Input, Grid, Loader } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Buttons from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import { ReCaptchaKey } from "../functions/constants"
import Network from "../Utilis/Network";
import server from "../functions/server";
import UserMobile from "../functions/usermobilenumber";
import UserName from "../functions/username";

export default class Enquiry extends React.Component {
  constructor() {
    super();
    this.recaptchaRef = React.createRef();
    this.state = {
      shopMobile: "",
    };
  }

  enquiry = async () => {
    if (this.state.shopMobile) {
      this.setState({
        shopShopMobileNumberDialog: true
      });
      return;
    }
    let context = this;
    let userMobile = UserMobile();
    let userName = UserName();
    if (!userMobile) {
      userMobile = localStorage.getItem("userMobile");
      userName = localStorage.getItem("userName");
      if (!userMobile) {
        this.setState({ openEnquiryDialog: true });
        return;
      }
    }
    let productId = this.props.productId;
    let shopId = this.props.shopId;
    const token = await this.recaptchaRef.current.executeAsync();
    this.recaptchaRef.current.reset();
    let payload = {
      product_id: productId,
      shop_id: shopId,
      user_name: userName,
      user_mobile: userMobile,
      'g-recaptcha-response': token
    };
    this.setState({ load: true });
    let url = server() + "/enquiry/add.php";
    Network.POST(
      url,
      payload,
      (data) => {
        console.log(data);
        if (data === 0) {
          alert("Error");
          context.setState({ load: false });
        } else {
          context.setState({
            load: false,
            shopShopMobileNumberDialog: true,
            openEnquiryDialog: false,
            shopMobile: data,
          });
        }
      },
      (err) => {
        console.log("err", err);
        context.setState({ load: false });
      }
    );
  };

  newUserWithoutOTP = () => {
    this.setState({ otpVerification: false, load: false, otp: "" });
    localStorage.setItem("userMobile", this.state.userMobile);
    localStorage.setItem("userName", this.state.userName);
    this.enquiry();
  }

  newUser = (noOTP) => {
    let context = this;
    if (!this.state.userMobile) {
      alert("Please Entry Mobile Number!!!");
      return;
    }
    if (this.state.userMobile.length !== 10) {
      alert("Enter Mobile Number Properly");
      return;
    }
    if (!this.state.userName) {
      alert("Please Entry Your Name!!!");
      return;
    }
    if (noOTP) {
      this.newUserWithoutOTP();
      return;
    }
    let url = "https://you.strackit.com/ALUMNI/loginandsignup/sendotp.php";
    let payload = { mobile: this.state.userMobile };
    this.setState({ load: true });
    Network.POST(
      url,
      payload,
      (data) => {
        try {
          if (data.message === "Message Sent") {
            context.setState({
              otpVerification: true,
              openEnquiryDialog: false,
              load: false,
            });
          }
        } catch (e) {
          alert("Errors");
          context.setState({ load: false });
        }
      },
      (err) => {
        context.setState({ load: false });
      }
    );
  };

  textHandle = (value) => (event) => {
    this.setState({ [value]: event.target.value });
  };

  submitOTP = () => {
    let context = this;
    if (!this.state.otp) {
      alert("Enter OTP!!!");
      return;
    }
    if (this.state.otp.length !== 6) {
      alert("Enter OTP Properly!!!");
      return;
    }
    let url = "https://you.strackit.com/ALUMNI/loginandsignup/verifyotp.php";
    let payload = { otp: this.state.otp, mobile: this.state.userMobile };
    this.setState({ load: true });

    Network.POST(
      url,
      payload,
      (data) => {
        if (data === 1) {
          context.setState({ otpVerification: false, load: false, otp: "" });
          localStorage.setItem("userMobile", context.state.userMobile);
          localStorage.setItem("userName", context.state.userName);
          context.enquiry();
        } else {
          alert("Otp is Wrong");
          context.setState({ load: false });
        }
      },
      (err) => {
        context.setState({ load: false });
      }
    );
  };

  checkWebsite = (website, shopName) => {
    website = website.website;
    shopName = shopName.split(" ").join("-");
    shopName = "https://business.strackit.com/" + shopName;
    let value = 1;
    if (website === "http://undefined" || website === "http://") {
      value = 0;
    }
    let x = <div />;
    if (value === 1) {
      x = (
        <div
          onClick={() => {
            window.location.href = website;
          }}
        >
          <a>
            <Buttons variant="outlined" color="primary">
              Visit Site
            </Buttons>
          </a>
        </div>
      );
    } else {
      x = (
        <a href={shopName} target="_blank">
          <Buttons variant="outlined" color="primary">
            Visit Site
          </Buttons>
        </a>
      );
    }
    return x;
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.load}
          aria-labelledby="responsive-dialog-title"
        >
          <Loader />
        </Dialog>

        {this.props.type == "link" ? (
          <div style={{ display: "flex" }}>
            <div style={{ fontWeight: "bold", fontSize: 16 }}>Price:</div>
            <div onClick={this.enquiry}>
              <u
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  cursor: "pointer",
                  color: "red",
                  width: "100%",
                  paddingLeft: "10px",
                }}
              >
                Shop Details
              </u>
            </div>
          </div>
        ) : (
          <Button fluid id="cartbutton1" positive onClick={this.enquiry}>
            Shop Details
          </Button>
        )}
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          about="Enquiry Submission"
          sitekey={ReCaptchaKey}
        />

        <Dialog
          open={this.state.openEnquiryDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle style={{ width: 600 }}>
            Enter Your Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Input
                placeholder="Name"
                fluid
                value={this.state.userName}
                onChange={this.textHandle("userName")} />
              <br />
              <Input
                placeholder="Mobile Number"
                fluid
                value={this.state.userMobile}
                onChange={this.textHandle("userMobile")} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ openEnquiryDialog: false });
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => this.newUser(true)}>Submit</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.shopShopMobileNumberDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle style={{ width: 400 }}>Shop Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  <div style={{ fontSize: 17, fontWeight: "bold" }}>Name</div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13}>
                  <div style={{ fontSize: 17, fontWeight: "bold" }}>
                    {this.props.shopName}
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  <div style={{ fontSize: 17, fontWeight: "bold" }}>Mobile</div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13}>
                  <div style={{ fontSize: 17, fontWeight: "bold" }}>
                    <a href={"tel:" + this.state.shopMobile.phone}>
                      {this.state.shopMobile.phone}
                    </a>
                  </div>
                </Grid.Column>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.checkWebsite(this.state.shopMobile, this.props.shopName)}
            <Buttons
              variant="outlined"
              color="black"
              onClick={() => {
                this.setState({ shopShopMobileNumberDialog: false });
              }}
            >
              Cancel
            </Buttons>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.otpVerification}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle style={{ width: 600 }}>Enter OTP</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Input
                placeholder="OTP"
                fluid
                value={this.state.otp}
                onChange={this.textHandle("otp")}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ otpVerification: false });
              }}
            >
              Cancel
            </Button>
            <Button onClick={this.submitOTP}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
