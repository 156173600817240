import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Header, Grid } from "semantic-ui-react";
import {
  JSONLD,
  Generic,
  GenericCollection,
  Offer,
} from "react-structured-data";

import server from "../functions/server";
import Product from "./product";
import userID from "../functions/userid";
import DocumentMeta from "react-document-meta";
import domain from "../functions/domain";
import Network from "../Utilis/Network";

export default class Category extends React.Component {
  constructor() {
    super();
    this.state = {
      nameOfTheCityShopLocated: "",
      descriptionOfTheShop: "",
      categoryOfTheShop: "",
      out: [],
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts() {
    let context = this;
    let userId = userID();
    let url =
      server() +
      "/shop/productsbycategory.php?shop=" +
      this.props.name +
      "&category=" +
      this.props.category +
      "&userid=" +
      userId;
    context.setState({
      load: true,
    });
    let payload;
    Network.POST(
      url,
      payload,
      (data) => {
        let nameOfTheCityShopLocated = "",
          descriptionOfTheShop = "",
          categoryOfTheShop = "";
        let out = [];
        try {
          out = data;
          out.map((c) => {
            let extra =
              this.props.name +
              " - " +
              c.category +
              " in " +
              c.address +
              " " +
              c.city +
              " - Click to view the products and order now";
            if (!c.description) {
              c.description = extra;
            } else {
              c.description += " " + extra;
            }
            nameOfTheCityShopLocated = c.city;
            descriptionOfTheShop = c.description;
            categoryOfTheShop = c.category;
            out = c.products;
          });
        } catch (e) {}
        context.setState({
          out,
          nameOfTheCityShopLocated,
          descriptionOfTheShop,
          categoryOfTheShop,
          load: false,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateData(data) {
    this.setState({
      out: data,
    });
  }

  processProducts() {
    if (typeof this.state.out != "object") {
      return <div />;
    }
    let out = this.state.out.map((x) => {
      return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Product
            badgeUpdate={this.props.badgeUpdate}
            product={x}
            data={this.state.out}
            shopName={this.props.name}
            update={this.updateData.bind(this)}
          />
        </Grid.Column>
      );
    });
    out = (
      <center style={{ padding: "1%" }}>
        <Grid>{out}</Grid>
      </center>
    );
    return out;
  }

  seprateUrl(shopName) {
    try {
      shopName = shopName.split(" ").join("-");
    } catch (e) {
      shopName = "";
    }
    return shopName;
  }

  getJSONLD = () => {
    let { out } = this.state,
      items = [];
    if (out.length < 1) return;
    for (var i = 0; i < out.length; i++) {
      let item = out[i];
      let url =
        domain() +
        "product?name=" +
        encodeURI(item.product_name.split(" ").join("-")) +
        "shop=" +
        item.shop_id +
        "&product=" +
        item.product_id +
        "&shopName=" +
        this.props.name.split(" ").join("-");
      items.push(
        <Generic
          jsonldtype="ListItem"
          schema={{ position: item.product_category_id }}
        >
          <Generic
            type="item"
            jsonldtype="Product"
            schema={{
              name: item.product_name,
              description: item.description,
              image: item.feature_image,
              productId: item.product_id,
              category: item.category,
              url: url,
            }}
          >
            <Generic
              type="offers"
              jsonldtype="Offer"
              schema={{ priceCurrency: "INR", price: item.prize }}
            />
          </Generic>
        </Generic>
      );
    }
    return (
      <JSONLD>
        <Generic
          type="ItemList"
          jsonldtype="ItemList"
          schema={{ url: window.location.href }}
        >
          <GenericCollection type="itemListElement">{items}</GenericCollection>
        </Generic>
      </JSONLD>
    );
  };

  render() {
    let shopName =
      this.props.name +
      " - " +
      this.state.categoryOfTheShop +
      " in " +
      this.state.nameOfTheCityShopLocated;
    const meta = {
      title: shopName,
      description: this.state.shopDesc,
      canonical:
        domain() + this.seprateUrl(this.props.name + "/" + this.props.category),
      meta: {
        charset: "utf-8",
        name: { keywords: shopName },
      },
    };
    return (
      <DocumentMeta {...meta}>
        {this.getJSONLD()}
        <div>
          {this.state.load && <LinearProgress />}
          {this.processProducts()}
        </div>
      </DocumentMeta>
    );
  }
}
