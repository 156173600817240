/* eslint-disable array-callback-return */
import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid, Segment, Header, Icon, Card, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Shop from "./shop";
import $ from "jquery";
import server from "../functions/server";
import userId from "../functions/userid";
import Network from "../Utilis/Network";

export default class Shops extends React.Component {
  constructor() {
    super();
    this.state = {
      longitude: 0,
      latitude: 0,
      Products: [],
    };
  }

  componentDidMount() {
    this.getShops();
  }

  componentDidUpdate(props) {
    if (props.allshop !== this.props.allshop) {
      this.getShops();
    }
  }

  getShops() {
    console.log("Ff");
    let context = this;
    let url = server() + "/home/identicalshops.php";
    let allshop = this.props.allshop;
    if (allshop) {
      allshop = "yes";
    } else {
      allshop = "no";
    }
    console.log("Fft");
    let payload = {
      longitude: this.props.longitude,
      latitude: this.props.latitude,
      allshop: allshop,
    };
    context.setState({ load: true });
    Network.POST(
      url,
      payload,
      (data) => {
        console.log("d");
        console.log(data);
        let out = [];
        try {
          out = data;
          out.map((x) => {
            x.cart = 0;
          });
        } catch (e) {
          alert(e);
        }
        context.setState({ out: out, load: false });
      },
      (err) => {
        context.setState({ load: false });
        console.log(err);
      }
    );
  }

  onClickFunction(buttonlink) {
    $("#link" + buttonlink).click();
  }

  processShop(type, category, buttonlink) {
    let i = 0;
    return type[category].map((x) => {
      ++i;
      if (i >= 7) {
        if (i === 8) {
          return (
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <Card
                onClick={this.onClickFunction.bind(this, buttonlink)}
                style={{
                  height: "100%",
                  backgroundColor: "#9D9EA1",
                  cursor: "pointer",
                }}
              >
                <Card.Content extra>
                  <center>
                    <div style={{ marginTop: "65%", color: "white" }}>
                      <b>MORE</b>
                    </div>
                  </center>
                </Card.Content>
              </Card>
            </Grid.Column>
          );
        }
      }
      return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Shop shop={x} />
        </Grid.Column>
      );
    });
  }
  processShops() {
    let buttonlink = 0;
    if (typeof this.state.out != "object") {
      return <div />;
    }
    let context = this;
    let type = {};
    // eslint-disable-next-line array-callback-return
    this.state.out.map((x) => {
      if (type[x.category]) {
        type[x.category].push(x);
        // eslint-disable-next-line array-callback-return
        return;
      }
      type[x.category] = [];
      type[x.category].push(x);
    });
    let out = Object.keys(type).map((x) => {
      let links = x.split(" ").join("-");
      buttonlink++;
      return (
        <div>
          <div style={style.segment}>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={13}>
                <Header align="left" as="h3" color="blue">
                  {x}
                </Header>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                {
                  // (type[x].length > 7) &&
                  <Link to={"/shops/" + links}>
                    <Header
                      id={"link" + buttonlink}
                      align="right"
                      as="h3"
                      color="green"
                    >
                      View More
                    </Header>
                  </Link>
                }
              </Grid.Column>
            </Grid>
            <Grid style={{ paddingRight: "-5%" }}>
              {context.processShop(type, x, buttonlink)}
            </Grid>
          </div>
        </div>
      );
    });
    return out;
  }

  render() {
    return (
      <div>
        {this.state.load && <LinearProgress />}
        {this.processShops()}
      </div>
    );
  }
}

const style = {
  segment: {
    padding: "2%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#F0F0F0",
  },
  viewmorebutton: {
    alignItems: "right",
    backgroundColor: "red",
  },
};
