import React, { Component } from "react";
import { Image, Modal, Button, Card, Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import userID from "../functions/userid";
import server from "../functions/server";
import { Paper, InputBase, Divider, IconButton } from "@material-ui/core";
import Minimize from "@material-ui/icons/RemoveCircleOutline";
import Add from "@material-ui/icons/AddCircleOutline";
import DirectionsIcon from "@material-ui/icons/Directions";
import DocumentMeta from "react-document-meta";
import domain from "../functions/domain";
import Network from "../Utilis/Network";

const style = {
  image: {
    position: "relative",
    float: "left",
    minHeight: 200,
    maxHeight: 200,
  },
  card: {
    textAlign: "left",
  },
  description: {
    maxHeight: "2.6em",
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
  },
};

class Searchproduct extends Component {
  state = {
    load: false,
  };

  changeQty = (cart, productId, type) => (event) => {
    let userId = userID();
    if (userId == null) {
      let url = window.location.href;
      window.location.href = "https://you.strackit.com?redirectto=" + url;
      return;
    }
    if (type === "plus") {
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart + 1;
    } else if (type === "minus") {
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart - 1;
    } else {
      cart = event.target.value;
    }
    let data = this.props.data;
    data.map((x) => {
      if (x.type === "product") {
        if (x.id === productId) {
          let no_stock = 0;
          try {
            no_stock = Number(x.no_stock);
            cart = Number(cart);
            if (cart > no_stock) {
              alert("Out Of Stock");
            } else {
              x.cart = cart;
              this.cartChange(cart, x.shop_id, x.product_id);
            }
          } catch (e) {}
        }
      }
    });
    this.props.update(data);
  };

  cartChange(cart, shopid, product_id) {
    let context = this;
    let userId = userID();
    let payload = {
      productId: product_id,
      userId: userId,
      shopId: shopid,
      qty: cart,
    };
    this.setState({
      load: true,
      loadMsg: "Adding To Cart",
    });
    let url = server() + "/product/cart.php";
    Network.POST(
      url,
      payload,
      (data) => {
        if (data === 1) {
          context.setState({
            load: false,
            loadMsg: "",
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  render() {
    let cart = 0;
    let productId = 0;
    let result = "Search Result : " + this.props.search;
    let getresult = <div />;
    if (this.props.data) {
      if (this.props.data.length >= 1) {
        getresult = this.props.data.map((z) => {
          let fetureimage = z.feature_image;
          let name = z.shop_name;
          let path = name.split(" ").join("-");
          let url = "/product?shop=" + z.shop_id + "&product=" + z.count;
          if (z.type === "productdetail") {
            return (
              <Grid.Column mobile={12} tablet={16} computer={4}>
                <Link to={url}>
                  <Card fluid style={style.card}>
                    {fetureimage ? (
                      <img style={style.image} src={fetureimage} alt="" />
                    ) : (
                      <img
                        style={style.image}
                        src={require("../media/empty.jpg")}
                        alt=""
                      />
                    )}
                    <Card.Content style={style.content}>
                      <Card.Header>{name}</Card.Header>
                      <Card.Meta>
                        <span className="date"> </span>
                      </Card.Meta>
                      <Card.Description style={style.description}>
                        {"Product"}
                      </Card.Description>
                      <br />
                      <br />
                    </Card.Content>
                  </Card>
                </Link>
              </Grid.Column>
            );
          }
        });
      } else {
        return (
          <div>
            <center style={{ color: "red", fontSize: 22, paddingTop: "22%" }}>
              No Product is Found!!!!!
            </center>
          </div>
        );
      }
    } else {
      return <div>No Shop!!!</div>;
    }
    return (
      <div>
        <Grid style={{ padding: "2%" }}>{getresult}</Grid>
      </div>
    );
  }
}

export default Searchproduct;
