import React, { Component } from "react";
import { Card, Icon, Image, Grid } from "semantic-ui-react";
import server from "../functions/server";
import domain from "../functions/domain";
// import Paper from '@material-ui/core/Paper';
import InputBase from "@material-ui/core/InputBase";
// import Divider from '@material-ui/core/Divider';
import IconButton from "@material-ui/core/IconButton";
import Minimize from "@material-ui/icons/RemoveCircleOutline";
import Add from "@material-ui/icons/AddCircleOutline";
// import DirectionsIcon from '@material-ui/icons/Directions';
import userID from "../functions/userid";
import cartss from "../functions/cart";
import EnquireNow from "../enquiry";
import Network from "../Utilis/Network";
import { Link } from "react-router-dom";

const style = {
  image: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
  },
  card: {
    textAlign: "left",
  },
  header: {
    fontSize: 15,
  },
  description: {
    minHeight: 70,
    maxHeight: 70,
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    wordWrap: "break-word",
    overflow: "hidden",
  },
};

class Related extends Component {
  state = {};

  getProduct(productId, shopId) {
    let cityUrl =
        this.props.city.split(" ").join("-") +
        "/" +
        this.props.area.split(" ").join("-") +
        "/";
    return (
      "/product/" +
      cityUrl +
      this.props.shop.split(" ").join("-") +
      "/" +
      encodeURI(this.props.name.split(" ").join("-")) +
      "/" +
      this.props.shopid +
      "/" +
      this.props.id
    );
  }

  changeQty = (cart, productId, type) => (event) => {
    let userId = userID();
    if (userId == null) {
      let url = window.location.href;
      window.location.href = "https://you.strackit.com?redirectto=" + url;
      return;
    }
    if (cart == null) {
      cart = 0;
    }
    if (type === "plus") {
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart + 1;
    } else if (type === "minus") {
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart - 1;
    } else {
      cart = event.target.value;
    }
    let data = this.props.data;
    data.map((x) => {
      if (x.type === "relatedproduct") {
        if (x.id === productId) {
          let no_stock = 0;
          try {
            no_stock = Number(x.no_stock);
            cart = Number(cart);
            if (cart > no_stock) {
              alert("Out Of Stock");
            } else {
              x.cart = cart;
              this.cartChange(cart, x.shop_id, productId);
            }
          } catch (e) {}
        }
      }
    });
    this.props.update(data);
  };

  cartChange(cart, shopid, product_id) {
    let context = this;
    let userId = userID();
    product_id = Number(product_id);
    let payload = {
      productId: product_id,
      userId: userId,
      shopId: shopid,
      qty: cart,
    };
    this.setState({
      load: true,
      loadMsg: "Adding To Cart",
    });

    let url = server() + "/product/addcart.php";

    Network.POST(
      url,
      payload,
      (data) => {
        context.setState({ load: false });
        if (data === 1) {
          context.setCookies(
            product_id,
            userId,
            shopid,
            cart,
            context.props.shop
          );
        }
      },
      (err) => {
        context.setState({ load: false });
        console.log(err);
      }
    );
  }

  setCookies(product_id, userId, shopid, cart, shopName) {
    let carts = cartss();
    let arr = [];
    if (carts === 0) {
      carts = [];
    } else {
      try {
        carts = JSON.parse(carts);
      } catch (e) {
        carts = [];
      }
    }
    let value = 0;
    carts.map((c) => {
      if (c.productId === product_id && c.user === userId) {
        value = 1;
      }
    });

    if (value === 1) {
      carts.map((c) => {
        if (c.productId === product_id && c.user === userId) {
          c.quantity = cart;
        }
      });
    } else {
      arr.push({
        productId: product_id,
        user: userId,
        shop_id: shopid,
        quantity: cart,
        shopName: shopName,
      });
      carts = carts.concat(arr);
    }
    carts = JSON.stringify(carts);
    document.cookie = "cart =" + carts;
    this.props.updateCookies();
  }

  render() {
    let offerend = this.props.offerends;
    try {
      offerend = Number(offerend);
    } catch (e) {
      offerend = 0;
    }
    let c = <div />;
    if (offerend <= 0) {
      let price = this.props.price;
      let tax = this.props.tax;
      try {
        price = Number(price);
        tax = Number(tax);
        price = price + (price * tax) / 100;
        price = Number(price).toFixed(0);
      } catch (e) {}
      c = (
        <div style={{ color: "green", fontWeight: "bold" }}>
          <br />
          {"₹ " + price}
        </div>
      );
    } else {
      let price = this.props.price;
      let tax = this.props.tax;
      let offer = this.props.offer;
      try {
        price = Number(price);
        tax = Number(tax);
        offer = Number(offer);
        price = (price + (price * tax) / 100).toFixed(0);
        offer = (offer + (offer * tax) / 100).toFixed(0);
      } catch (e) {}
      c = (
        <div>
          <strike id="strike">{"₹ " + price}</strike>
          <br />
          <div style={{ color: "green", fontWeight: "bold" }}>
            {"₹ " + offer}
          </div>
        </div>
      );
    }
    let name;
    if (this.props.name.length > 53) {
      name = this.props.name.substring(0, 35) + ".....";
    } else {
      name = this.props.name;
    }

    return (
      <div>
        <Link to={this.getProduct.bind(this, this.props.id, this.props.shopid)()}>
          <Card fluid>
            {this.props.feature ? (
              <img style={{ height: 250 }} src={this.props.feature} alt="" />
            ) : (
              <img
                style={{ height: 250 }}
                src={require("../media/empty.jpg")}
                alt=""
              />
            )}
            <Card.Content style={{ height: 80, cursor: "pointer" }}>
              <Card.Header style={style.header}>{name}</Card.Header>
              <Card.Meta>
                <span className="date">{this.props.shop}</span>
              </Card.Meta>
            </Card.Content>
            <Card.Content extra>
              <Grid>
                <Grid.Column mobile={8} tablet={8} computer={9}>
                  <span>
                    {this.props.viewPrice === "0" ? (
                      <div style={{ marginTop: "10%", marginBottom: "8%" }}>
                        <EnquireNow
                          productName={this.props.name}
                          productId={this.props.productId}
                          shopId={this.props.shopid}
                          shopName={this.props.shop}
                          type="link"
                        />
                      </div>
                    ) : (
                      <div>{c}</div>
                    )}
                  </span>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={7}>
                  {this.props.viewPrice === "0" ? (
                    <div />
                  ) : (
                    <div style={{ display: "flex", width: "100%" }}>
                      <IconButton
                        style={{ width: "20%" }}
                        className={style.iconButton}
                        aria-label="menu"
                      >
                        <Minimize
                          onClick={this.changeQty(
                            this.props.cartcount,
                            this.props.productId,
                            "minus"
                          )}
                        />
                      </IconButton>
                      <InputBase
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={this.changeQty(
                          this.props.cartcount,
                          this.props.productId,
                          "other"
                        )}
                        value={this.props.cartcount}
                        placeholder="Add To Cart"
                      />
                      <IconButton
                        style={{ width: "20%" }}
                        className={style.iconButton}
                        aria-label="menu"
                      >
                        <Add
                          onClick={this.changeQty(
                            this.props.cartcount,
                            this.props.productId,
                            "plus"
                          )}
                        />
                      </IconButton>
                    </div>
                  )}
                </Grid.Column>
              </Grid>
            </Card.Content>
          </Card>
        </Link>
      </div>
    );
  }
}

export default Related;
