const Network = {
  POST: (url, json, onSuccess, onError) => {
    const vv = new URLSearchParams(json);
    fetch(url, {
      method: "post",
      body: vv,
    })
      .then((res) => res.json())
      .then(onSuccess)
      .catch(onError)
      .catch((E) => {});
  },
};

export default Network;
