import React, { Component } from "react";
import $ from "jquery";
import {
  Grid,
  Image,
  Button,
  Input,
  Breadcrumb,
  Header,
  Modal,
  Table,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "./product.css";
import { Link, withRouter } from "react-router-dom";
import Home from "@material-ui/icons/Home";
import {
  JSONLD,
  Product as Prod,
  LocalBusiness,
  Generic,
} from "react-structured-data";
import IconButton from "@material-ui/core/IconButton";

import server from "../functions/server";
import Images from "./images";
import Related from "./related";
import Title from "./title";
import UserId from "../functions/userid";
import userID from "../functions/userid";
import userName from "../functions/username";
import domain from "../functions/domain";
import cartCount from "../functions/cart";
import DocumentMeta from "react-document-meta";
import cartCounts from "../functions/ca";
import cartss from "../functions/cart";
import EnquireNow from "../enquiry";
import Network from "../Utilis/Network";

class ViewRest extends Component {
  state = {
    product: [],
    error: "",
    open: false,
    shopid: "",
    shopname: "",
    category: "",
    load: false,
    loadMsg: "",
    shopnames: "",
    shopDesc: "",
    shopCity: "",
    shopArea: "",
  };

  componentDidMount() {
    this.view();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.productId !== prevProps.match.params.productId) {
      this.view();
      window.scrollTo(0, 0);
    }
  }

  view() {
    let context = this;
    let urlString = window.location.href;
    let url = new URL(urlString);
    let shopName = this.props.match.params.shopName;
    let productId = this.props.match.params.productId;
    let shopId = this.props.match.params.shopId;
    let userID = UserId();
    let payload = { productId: productId, shopId: shopId, userID: userID };
    let urls = server() + "/product/view.php";

    context.setState({
      load: true,
      loadMsg: "Loading Product",
      shopnames: shopName,
    });

    Network.POST(
      urls,
      payload,
      (data) => {
        let shopCity, shopDesc, shopArea;
        let out = [];
        data.map((c) => {
          shopCity = c.city;
          shopDesc = c.description;
          shopArea = c.area;
          let prods = c.products;
          out = prods;
        });
        out.map((c) => {
          c.oldcartqty = c.cart;
          c.category = JSON.stringify(c.category);
        });
        if (out.length >= 1) {
          let shop = out[0].shop_name;
          let category = JSON.parse(out[0].category);
          shop = shop.replace(/ /g, "-");
          context.setState({
            product: out,
            shopname: shop,
            category,
            load: false,
            shopCity,
            shopDesc,
            shopArea,
          });
        } else {
        }
      },
      (err) => {
        context.setState({ load: false, loadMsg: "" });
      }
    );
  }

  addCart(shopName) {
    let userId = userID();
    if (userId == null) {
      window.open(
        "https://you.strackit.com?redirectto=https://shop.strackit.com"
      );
      return;
    }
    let context = this;
    let urlString = window.location.href;
    let url = new URL(urlString);
    let productId = this.props.match.params.productId;
    let shopId = this.props.match.params.shopId;
    let quantity = 1;
    if (quantity === "" || quantity === 0) {
      quantity = 1;
    }
    let data = {
      productId: productId,
      userId: userId,
      shopId: shopId,
      qty: quantity,
    };
    this.setState({ load: true, loadMsg: "Adding To Cart" });
    let urls = server() + "/product/cart.php";

    Network.POST(
      urls,
      data,
      (data) => {
        context.setState({ load: false });
        if (data === 1) {
          context.setCookies(productId, userId, shopId, quantity, shopName);
        }
      },
      (err) => {
        alert("Error!!!");
        context.setState({ load: false });
      }
    );
  }

  replaceString(shopName) {
    try {
      shopName = shopName.replace(" ", "-");
    } catch (e) {}
    return shopName;
  }

  buyProduct(shopName) {
    let context = this;
    let userId = userID();
    if (userId == null) {
      window.open(
        "https://you.strackit.com?redirectto=https://shop.strackit.com"
      );
      return;
    }
    let urlString = window.location.href;
    let url = new URL(urlString);
    shopName = this.replaceString(shopName);
    let productId = this.props.match.params.productId;
    let shopId = this.props.match.params.shopId;
    let quantity = 1;
    if (quantity === "" || quantity === 0) {
      quantity = 1;
    }
    let data = {
      productId: productId,
      userId: userId,
      shopId: shopId,
      qty: quantity,
    };
    this.setState({ load: true, loadMsg: "Buying Product" });
    let urls = server() + "/product/cart.php";
    Network.POST(
      urls,
      data,
      (data) => {
        if (data === 1) {
          context.setCookies(productId, userId, shopId, quantity, shopName);
          window.location.href =
            domain() +
            "order?shop=" +
            this.props.match.params.shopId +
            "&shopname=" +
            shopName;
        }
      },
      (err) => {
        context.setState({ load: false });
      }
    );
  }

  setCookies(product_id, userId, shopid, cart, shopName) {
    let carts = cartss();
    let arr = [];
    if (carts === 0) {
      carts = [];
    } else {
      try {
        carts = JSON.parse(carts); 
      } catch (e) {
        carts = [];
      }
    }
    let value = 0;
    carts.map((c) => {
      if (c.productId === product_id && c.user === userId) {
        value = 1;
      }
    });

    if (value === 1) {
      carts.map((c) => {
        if (c.productId === product_id && c.user === userId) {
          c.quantity = cart;
        }
      });
    } else {
      arr.push({
        productId: product_id,
        user: userId,
        shop_id: shopid,
        quantity: cart,
        shopName: shopName,
      });
      carts = carts.concat(arr);
    }
    carts = JSON.stringify(carts);
    document.cookie = "cart =" + carts;
    this.setState({ dumy: true });
  }

  show = (size) => () => this.setState({ size: size, open: true });

  close = () => this.setState({ open: false });

  getImages() {
    let x = [];
    this.state.product.map((z) => {
      if (z.type === "productimage") {
        if (z.id) {
          x.push(
            <Grid.Column mobile={8} tablet={3} computer={3}>
              <Images
                style={{ display: "inline" }}
                id={z.id}
                image={z.product_name}
              />
            </Grid.Column>
          );
        }
      }
    });
    return x;
  }

  getOffers() {
    let x = [];
    this.state.product.map((z) => {
      if (z.type === "productdetails") {
        let offer = z.offerends;
        try {
          offer = Number(offer);
        } catch (e) {}
        if (offer <= 0) {
          let price = z.prize;
          let tax = z.tax;
          try {
            price = Number(price);
            tax = Number(tax);
            price = price + (price * tax) / 100;
            price = Number(price);
            price = Math.round(price);
          } catch (e) {}
          x.push(
            <div>
              <h3>RS: {price}</h3>
            </div>
          );
        } else {
          let price = z.prize;
          let tax = z.tax;
          let discount = z.discount;
          try {
            price = Number(price);
            tax = Number(tax);
            discount = Number(discount);
            price = price + (price * tax) / 100;
            discount = discount + (discount * tax) / 100;
            price = Number(price);
            price = Math.round(price);
            discount = Math.round(discount);
          } catch (e) {}
          x.push(
            <div>
              <p id="specialprice">
                Special price available for {z.offerends} products
              </p>
              <h3>
                <strike id="strike">RS: {price}</strike> RS: {discount}
              </h3>
            </div>
          );
        }
      }
    });
    return x;
  }

  updateCookies() {
    this.setState({ dumy: true });
  }

  getRelated() {
    let x = [];
    this.state.product.map((z) => {
      if (z.type === "relatedproduct") {
        x.push(
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Related
              id={z.user_id}
              feature={z.feature_image}
              name={z.product_name}
              price={z.prize}
              offer={z.discount}
              shop={z.shop_name}
              shopid={z.shop_id}
              productId={z.id}
              city={this.state.shopCity}
              area={this.state.shopArea}
              tax={z.tax}
              cartcount={z.cart}
              data={this.state.product}
              offerends={z.offerends}
              update={this.updateData.bind(this)}
              updateCookies={this.updateCookies.bind(this)}
            />
          </Grid.Column>
        );
      }
    });
    return x;
  }

  updateData(data) {
    this.setState({ product: data });
  }

  getOutOfStock() {
    let x = [];
    this.state.product.map((z) => {
      if (z.type === "productdetails") {
        if (z.no_stock === 0) {
          x.push(
            <div>
              <div id="outofstock">Out of stock!!!</div>
            </div>
          );
        }
      }
    });
    return x;
  }

  qtyChange = (id, noStock) => (event) => {
    try {
      noStock = Number(noStock);
    } catch (e) {}
    if (event.target.value > noStock) {
      alert("Out Of Stock");
    } else {
      let data = this.state.product;
      data.map((x) => {
        if (x.type === "productdetails") {
          x.cart = event.target.value;
        }
      });
      this.setState({ product: data });
    }
  };

  specification(specification) {
    if (!typeof specification === "array" || specification.length < 1) {
      return "";
    }
    let body = specification.map((s) => {
      return (
        <Table.Row>
          <Table.Cell>{s.name}</Table.Cell>
          <Table.Cell>{s.value}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <Table definition>
        <Table.Body>{body}</Table.Body>
      </Table>
    );
  }

  getShopJSONLD = (shop, product) => {
    if (!shop) return;
    let { latitude, longitude } = shop;
    let { id, shop_name, address, area, city, feature_image } = shop;
    if (!feature_image) {
      feature_image = product.feature_image;
    }
    try {
      latitude = parseFloat(latitude);
      longitude = parseFloat(longitude);
    } catch (e) {
      latitude = 0;
      longitude = 0;
    }
    let out = (
      <JSONLD>
        <Generic
          type="localBusiness"
          jsonldtype="LocalBusiness"
          schema={{
            name: shop_name,
            image: feature_image,
            address: address,
            "@id": id,
            location: city,
          }}
        >
          <Generic
            type="geo"
            jsonldtype="GeoCoordinates"
            schema={{
              latitude,
              longitude,
            }}
          />
        </Generic>
      </JSONLD>
    );
    return out;
  };

  getProductJSONLD = (z) => {
    if (!z) return;
    let item = z;
    let out = (
      <JSONLD>
        <Prod
          name={item.product_name}
          description={item.description}
          image={item.feature_image}
          productId={item.product_id}
          category={item.category}
          url={window.location.href}
        >
          <Generic
            type="offers"
            jsonldtype="Offer"
            schema={{ priceCurrency: "INR", price: item.prize }}
          />
        </Prod>
      </JSONLD>
    );
    return out;
  };

  render() {
    let context = this;
    let shopName = context.state.shopname;
    let shopNameUrl = context.state.shopname;
    let categorylink =
      this.state.category &&
      this.state.category[0] &&
      this.state.category[0].category;
    try {
      categorylink = categorylink.split(" ").join("-");
      shopName = shopName.split("-").join(" ");
    } catch (e) {
      categorylink = "";
    }
    let image = this.getImages();
    let offer = this.getOffers();
    let related = this.getRelated();
    let outOfStock = this.getOutOfStock();
    let details = <div />;
    let title = "Product " + " in " + this.state.shopCity + " - " + shopName;
    let description =
      "Loading... Strackit Shops is used to shop and purchase products from various shops and near me.";
    let productZ = {};
    details = this.state.product.map((z) => {
      if (z.type === "productdetails") {
        productZ = z;
        title =
          z.product_name + " in " + this.state.shopCity + " - " + shopName;
        if (this.state.shopArea && this.state.shopArea.length > 0) {
          title =
            z.product_name +
            " in " +
            this.state.shopArea +
            ", " +
            this.state.shopCity +
            " - " +
            shopName;
        }
        description = z.description + " " + z.product_name + " near me in " + this.state.shopCity;
        let category = JSON.parse(z.category);
        if (!category || !category[0]) {
          category = [{ category: "" }];
        }
        let grid = 8;
        return (
          <div id="home">
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={grid}>
                <Image
                  src={z.feature_image}
                  onClick={this.show("tiny")}
                  fluid
                  id="images"
                  style={{ cursor: "pointer", objectFit: "cover" }}
                />
                <Grid style={{ paddingTop: 30 }}>{image}</Grid>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    <Breadcrumb>
                      <Breadcrumb.Section link href={"/"}>
                        Home
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right chevron" />
                      <Breadcrumb.Section link href={"/" + this.state.shopname}>
                        {z.shop_name}
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right chevron" />
                      <Breadcrumb.Section
                        active
                        href={"/" + this.state.shopname + "/" + categorylink}
                      >
                        {category[0].category}
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={8}>
                    {userID() === z.user_id && (
                      <Button
                        style={{ float: "right" }}
                        color="blue"
                        onClick={() => {
                          window.open(
                            "https://business.strackit.com/edit-product?id=" +
                              z.id +
                              "&from=" +
                              window.location.href
                          );
                        }}
                      >
                        Edit This Product
                      </Button>
                    )}
                  </Grid.Column>
                </Grid>
                <div style={{height: '400px', overflow: 'auto'}}>
                <h3>{z.product_name}</h3>
                {offer}
                {outOfStock}
                <br />
                <b>Description</b>
                <div
                  style={{
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    textalign: "justify",
                  }}
                > 
                  {
                    z.description ?
                    <p
                        dangerouslySetInnerHTML={{
                        __html: z.description.includes("http-equiv")
                            ? ""
                            : z.description
                            ? z.description
                            : "",
                        }}
                    />: null
                  }

                  {
                    z.long_description ?
                   
                    z.long_description.length > 350 && !this.state.viewmore ? 
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                        __html: z.long_description.substring(0, 350).includes("http-equiv")
                            ? ""
                            : z.long_description.substring(0, 350)
                            ? z.long_description.substring(0, 350)
                            : "",
                        }}
                    />
                      
                      <center style={{marginTop: '-3%'}}>
                        <u onClick={ () => this.setState({ viewmore: true })} 
                           style={{ color: 'blue', cursor: 'pointer', paddingTop: '-1000px' }}>
                          Read More
                        </u>
                      </center>
                    </div> :
                    <div><p
                        dangerouslySetInnerHTML={{
                        __html: z.long_description.includes("http-equiv")
                            ? ""
                            : z.long_description
                            ? z.long_description
                            : "",
                        }}
                    />
                    {
                      z.long_description.length > 350 && this.state.viewmore ?
                      <u onClick={ () => this.setState({ viewmore: false })} style={{ color: 'blue', cursor: 'pointer' }}>
                        <center>View Less</center>
                      </u>
                      : null
                    }
                    </div>: null
                  }
                </div>
              
                
                <br />
                <b />
                <div
                  style={{
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    textalign: "justify",
                  }}
                >
                  {this.specification(z.specification)}
                </div>
                <br />
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={6}>
                <EnquireNow
                  productName={z.product_name}
                  productId={z.id}
                  shopId={z.shop_id}
                  shopName={z.shop_name}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={5}>
                <Button
                  fluid
                  id="cartbutton"
                  onClick={this.addCart.bind(this, z.shop_name)}
                >
                  ADD TO CART
                </Button>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={5}>
                <Button
                  fluid
                  id="buybutton"
                  color="blue"
                  onClick={this.buyProduct.bind(this, z.shop_name)}
                >
                  BUY NOW
                </Button>
                <center id="outofstock">{this.state.error}</center>
              </Grid.Column>
            </Grid>
            <Modal
              size={this.state.size}
              open={this.state.open}
              onClose={this.close}
            >
              <Modal.Content>
                <Image src={z.feature_image} fluid id="images" />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  positive
                  icon="checkmark"
                  labelPosition="right"
                  onClick={this.close}
                  content="Okey"
                />
              </Modal.Actions>
            </Modal>
          </div>
        );
      }
    });
    const meta = {
      title: title,
      description: description,
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: { keywords: title },
      },
    };
    let homeIcon = (
      <Link to={"/" + context.state.shopname}>
        <IconButton color="inherit">
          <Home style={{ color: "white" }} />
        </IconButton>
      </Link>
    );
    return (
      <DocumentMeta {...meta}>
        {this.getShopJSONLD(productZ.shop, productZ)}
        {this.getProductJSONLD(productZ)}
        <div id="home" style={{ paddingTop: 70, paddingRight: "2%" }}>
          {this.state.shopname ? (
            <Title
              title={title}
              homeIcon={homeIcon}
              shopName={shopName}
              userName={userName()}
            />
          ) : (
            <div />
          )}
          {details}
          {related && related.length > 0 && related[0] && (
            <div style={{ paddingTop: 20, paddingBottom: 50 }}>
              <Header as="h3">Similar Products</Header>
              <br />
              <Grid>{related}</Grid>
            </div>
          )}
          <Dimmer active={this.state.load} page>
            <Loader />
            <Header as="h2" inverted style={{ marginTop: 100 }}>
              {this.state.loadMsg}
            </Header>
          </Dimmer>
        </div>
      </DocumentMeta>
    );
  }
}

export default withRouter(ViewRest);

// <p>Store: <Link to={"/"+z.shop_name.split(" ").join("-")}>{z.shop_name}</Link></p><br/>
