import React, { Component } from "react";
import $ from "jquery";
import server from "../../functions/server";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Card, Grid, Loader, Button } from "semantic-ui-react";
import View from "./view";
import UserId from "../../functions/userid";
import Title from "../title";
import Viewmaster from "./viewmaster";
import Network from "../../Utilis/Network";

class Myorder extends Component {
  state = {
    myorder: [],
    errorDialog: false,
  };

  componentDidMount() {
    this.myOrder();
  }

  myOrder() {
    let context = this;
    let userId = UserId();
    if (userId == null) {
      window.location.href =
        "https://you.strackit.com?redirectto=https://shop.strackit.com/myorders";
      return;
    }
    let data1 = { userId: userId };
    context.setState({ loading: true });
    let url = server() + "/order/getmyorder.php";
    Network.POST(
      url,
      data1,
      (myorder) => {
        console.log(myorder);
        context.setState({
          loading: false,
        });
        try {
          context.setState({
            myorder: myorder,
          });
        } catch (e) {
          alert("Try Again!!!");
        }
      },
      (err) => {
        alert("Try Again!!!");
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  updateorders(myorder) {
    this.setState({ myorder: myorder });
  }

  myorders() {
    return <Viewmaster ordermaster={this.state.myorder} />;
  }

  updateMyOrder(orderId) {
    let myOrder = this.state.myorder;
    for (var i = 0; i < myOrder.length; i++) {
      if (myOrder[i].id === orderId) {
        myOrder[i].status = 0 + "";
      }
    }
    this.setState({ myorder: myOrder });
  }

  render() {
    let context = this;
    let myorder = <div />;
    myorder = context.myorders();
    return (
      <div>
        <Title title="Myorder" />
        <br />
        <br />
        <br />
        <div style={{ padding: "1%" }}>
          {myorder}
          <Dialog
            open={this.state.loading}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" id1="widthdialog">
              {"LOADING..."}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Loader active inline="centered" />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.errorDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText>Try again!!!</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Myorder;
