import React from "react";
import $ from "jquery";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Grid, Segment, Header, Icon, Card } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  JSONLD,
  Generic,
  GenericCollection,
  Offer,
} from "react-structured-data";
import Product from "./product";
import Offers from "./offers";
import server from "../functions/server";
import userID from "../functions/userid";
import { makeStyles } from "@material-ui/core/styles";
import DocumentMeta from "react-document-meta";
import domain from "../functions/domain";
import Network from "../Utilis/Network";

const style = {
  image: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
  },
  card: { textAlign: "left" },
  description: {
    minHeight: 70,
    maxHeight: 70,
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  segment: {
    borderWidth: 1,
    padding: "2%",
    borderStyle: "solid",
    borderColor: "#F0F0F0",
  },
};

export default class Products extends React.Component {
  constructor() {
    super();
    this.state = {
      nameOfTheCityShopLocated: "",
      descriptionOfTheShop: "",
      categoryOfTheShop: "",
      areaOfTheShop: "",
      out: [],
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts() {
    let context = this;
    let userId = userID();
    let url = server() + "/shop/identicalproducts.php";
    let payload = { shop: context.props.name, id: userId };
    context.setState({ load: true });
    Network.POST(
      url,
      payload,
      (data) => {
        console.log(data);
        let nameOfTheCityShopLocated = "",
          descriptionOfTheShop = "",
          categoryOfTheShop = "",
          areaOfTheShop = "";
        let out = [];
        try {
          out = data;
          console.log(out);
          out.map((c) => {
            let extra =
              this.props.name +
              " - " +
              c.category +
              " in " +
              c.address +
              " " +
              c.city +
              " - Click to view the products and order now";
            if (!c.description) {
              c.description = extra;
            } else {
              c.description += " " + extra;
            }
            nameOfTheCityShopLocated = c.city;
            descriptionOfTheShop = c.description;
            categoryOfTheShop = c.category;
            areaOfTheShop = c.area;
            out = c.products;
          });
        } catch (e) {
          alert(e);
        }
        context.setState({
          out: out,
          load: false,
          nameOfTheCityShopLocated,
          descriptionOfTheShop,
          categoryOfTheShop,
          areaOfTheShop,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  updateData(data) {
    this.setState({ out: data });
  }

  onClickFunction(buttonlink) {
    $("#link" + buttonlink).click();
  }

  removeDuplicate(array) {
    let key = "category";
    return [...new Map(array.map((item) => [item[key], item])).values()];
  }

  processProducts() {
    let buttonlink = 1;
    if (typeof this.state.out != "object") {
      return <div />;
    }
    let context = this;
    let offers = [];
    let category = [];
    this.state.out.map((x) => {
      category.push({ category: x.category });
      if (x.type === "offer") {
        offers.push(x);
        return;
      }
    });
    category = this.removeDuplicate(category);
    category.map((c) => {
      let i = 0;
      this.state.out.map((o) => {
        if (c.category == o.category) {
          i = i + 1;
        }
      });
      c.count = i;
    });

    let productView = (
      <div>
        {category.map((c) => {
          let limitToView = 0;
          let catUrl = "";
          try {
            catUrl =
              "/" +
              context.props.name.split(" ").join("-") +
              "/" +
              c.category.split(" ").join("-");
          } catch (error) {}
          return (
            <div style={style.segment}>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={13}>
                  <Header as="h3" content={c.category} color="blue" />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  {c.count > 7 && (
                    <Link to={catUrl}>
                      <Header
                        id={"link" + buttonlink}
                        icon="arrow right"
                        color="green"
                        as="h4"
                        floated="right"
                        content="View More"
                      />
                    </Link>
                  )}
                </Grid.Column>
              </Grid>
              <Grid style={{ paddingRight: "-5%" }}>
                {this.state.out.map((x) => {
                  if (c.category == x.category && limitToView < 8) {
                    limitToView = limitToView + 1;
                    return (
                      <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Product
                          badgeUpdate={this.props.badgeUpdate}
                          product={x}
                          data={this.state.out}
                          shopName={this.props.name}
                          update={this.updateData.bind(this)}
                          city={this.state.nameOfTheCityShopLocated}
                          area={this.state.areaOfTheShop}
                        />
                      </Grid.Column>
                    );
                  }
                })}
              </Grid>
            </div>
          );
        })}
      </div>
    );

    if (offers.length >= 1) {
      productView = (
        <div>
          <Offers offers={offers} />
          {productView}
        </div>
      );
    }
    return productView;
  }

  seprateUrl(shopName) {
    try {
      shopName = shopName.split(" ").join("-");
    } catch (e) {
      shopName = "";
    }
    return shopName;
  }

  getJSONLD = () => {
    let { out } = this.state,
      items = [];
    if (out.length < 1) return;
    let cityUrl = "&city=" + this.state.nameOfTheCityShopLocated;
    cityUrl = this.state.nameOfTheCityShopLocated + "/";
    if (this.state.areaOfTheShop && this.state.areaOfTheShop.length > 0) {
      cityUrl =
        "&area=" +
        this.state.areaOfTheShop +
        "&city=" +
        this.state.nameOfTheCityShopLocated;
      cityUrl =
        this.state.nameOfTheCityShopLocated +
        "/" +
        this.state.areaOfTheShop +
        "/";
    }
    for (var i = 0; i < out.length; i++) {
      let item = out[i];
      let url =
        domain() +
        "product?name=" +
        encodeURI(item.product_name.split(" ").join("-")) +
        cityUrl +
        "&shop=" +
        item.shop_id +
        "&product=" +
        item.product_id +
        "&shopName=" +
        this.props.name.split(" ").join("-");
      url =
        domain() +
        "product/" +
        cityUrl +
        this.props.name.split(" ").join("-") +
        "/" +
        encodeURI(item.product_name.split(" ").join("-")) +
        "/" +
        item.shop_id +
        "/" +
        item.product_id;
      items.push(
        <Generic
          jsonldtype="ListItem"
          schema={{ position: item.product_category_id }}
        >
          <Generic
            type="item"
            jsonldtype="Product"
            schema={{
              name: item.product_name,
              description: item.description,
              image: item.feature_image,
              productId: item.product_id,
              category: item.category,
              url: url,
            }}
          >
            <Generic
              type="offers"
              jsonldtype="Offer"
              schema={{ priceCurrency: "INR", price: item.prize }}
            />
          </Generic>
        </Generic>
      );
    }
    return (
      <JSONLD>
        <Generic
          type="ItemList"
          jsonldtype="ItemList"
          schema={{ url: window.location.href }}
        >
          <GenericCollection type="itemListElement">{items}</GenericCollection>
        </Generic>
      </JSONLD>
    );
  };

  render() {
    let shopName =
      this.props.name +
      " - " +
      this.state.categoryOfTheShop +
      " in " +
      this.state.nameOfTheCityShopLocated;
    if (this.state.areaOfTheShop && this.state.areaOfTheShop.length > 0) {
      shopName =
        this.props.name +
        " - " +
        this.state.categoryOfTheShop +
        " in " +
        this.state.areaOfTheShop +
        ", " +
        this.state.nameOfTheCityShopLocated;
    }
    const meta = {
      title: shopName,
      description: this.state.descriptionOfTheShop,
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: { keywords: shopName },
      },
    };
    return (
      <DocumentMeta {...meta}>
        {this.getJSONLD()}
        <div>
          {this.state.load && <LinearProgress />}
          {this.processProducts()}
        </div>
      </DocumentMeta>
    );
  }
}
