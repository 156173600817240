import React, { Component } from 'react';
import userId from '../functions/userid';

class Login extends Component {

  componentDidMount()
  {
    this.checkcookies();
  }

  checkcookies()
  {
    let userid = userId();
    if (userid == null || userid === undefined) {
      window.location.href = "https://you.strackit.com?redirectto="+window.location.href;
    }
    else {
      this.props.gotostep(1);
    }
  }

  render() {
    return (
      <div>
        
      </div>
    );
  }
}

export default Login;
