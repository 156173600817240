import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from "@material-ui/icons/ShoppingCart";
import MoreIcon from "@material-ui/icons/MoreVert";
// import Avatar from '@material-ui/core/Avatar';
import userDp from "../functions/userdp";
import userId from "../functions/userid";
import LanguageIcon from "@material-ui/icons/Language";
import server from "../functions/server";
// import cartCounts from '../functions/ca';
import domain from "../functions/domain";
// import cartCount from '../functions/cart';
import cartss from "../functions/cart";
import { Icon } from "semantic-ui-react";
import Network from "../Utilis/Network";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class Title extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    badge: 0,
    cartlength: 0,
    shopName: "",
  };

  handleProfileMenuOpen = (event) => {
    if (!userId()) {
      window.location.href =
        "https://you.strackit.com?redirectto=https://shop.strackit.com";
      return;
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    if (!userId()) {
      window.location.href =
        "https://you.strackit.com?redirectto=https://shop.strackit.com";
      return;
    }
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  gotoMyorder() {
    window.location.href = domain() + "myorders";
  }

  componentDidMount() {
    let context = this;
    let urls;
    let data1;
    if (!userId()) {
    } else {
      let url = new URL(window.location.href);
      let shopId = url.searchParams.get("shop");
      if (shopId) {
        data1 = { userId: userId(), shopId: shopId };
        urls = server() + "/cart/get.php";
      } else {
        var pathArray = window.location.pathname.split("/");
        var shopname = pathArray[1];
        shopname = shopname.replace(/-/g, " ");
        data1 = { userId: userId(), shopName: shopname };
        urls = server() + "/cart/getbyname.php";
      }
      Network.POST(
        urls,
        data1,
        (data) => {
          try {
            document.cookie = "cart=" + data;
          } catch (e) {
            data = [];
            document.cookie = "cart=" + data;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  goToCart() {
    if (this.props.shopName) {
      window.location.href = domain() + "order?shopname=" + this.props.shopName;
    } else {
      let url = window.location.href;
      var pathArray = window.location.pathname.split("/");
      var shopname = pathArray[1];
      window.location.href = domain() + "order?shopname=" + shopname;
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.search(event.target.value);
  };

  goProfile() {
    window.location.href = "https://you.strackit.com/profile";
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.reload(true);
  }

  getBadge() {
    let url = new URL(window.location.href);
    let shopId;
    try {
      shopId = url.searchParams.get("shop");
    } catch (e) {}
    let user = userId();
    var pathArray = window.location.pathname.split("/");
    var shopname = pathArray[1];
    try {
      shopname = shopname.replace(/-/g, " ");
    } catch (e) {}
    let carts = cartss();
    let arr = [];
    if (carts == 0) {
      carts = [];
    } else {
      try {
        carts = JSON.parse(carts); 
      } catch (e) {
        carts = [];
      }
    }
    let total = 0;
    if (shopname) {
      carts.map((c) => {
        if (c.shopName === shopname && user === c.user) {
          total = total + 1;
        }
      });
    } else if (shopId) {
      carts.map((c) => {
        if (c.shop_id === shopId && user === c.user) {
          total = total + 1;
        }
      });
    } else {
      total = 0;
    }
    return total;
  }

  socialMedia(name) {
    window.location.href = domain() + "socialmedia?shopname=" + name;
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.goProfile.bind(this)}>Profile</MenuItem>
        <MenuItem onClick={this.deleteAllCookies.bind(this)}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        open={isMobileMenuOpen}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton color="inherit" onClick={this.gotoMyorder.bind(this)}>
            <NotificationsIcon />
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              {this.props.title}
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Product…"
                classes={{ root: classes.inputRoot, input: classes.inputInput }}
                onChange={this.handleChange("search")}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                color="inherit"
                onClick={this.socialMedia.bind(this, this.props.title)}
              >
                <LanguageIcon />
              </IconButton>
              <IconButton color="inherit" onClick={this.goToCart.bind(this)}>
                <Badge badgeContent={this.getBadge()} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton color="inherit" onClick={this.gotoMyorder.bind(this)}>
                <Icon name="shopping bag" size="small" />
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                {userDp() ? <AccountCircle /> : <AccountCircle />}
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    );
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Title);
