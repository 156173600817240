function userId() {
  var nameEQ = "ualum" + "=";
  var ca = decodeURIComponent(document.cookie).split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      let cook = c.substring(nameEQ.length, c.length);
      try {
        cook = JSON.parse(cook);
      } catch (e) {
        return null;
      }
      return cook.user_id;
    }
  }
  return null;
}

export default userId;
