import React from "react";
import Slider from "react-slick";
import { Image } from "semantic-ui-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const style = {
  image: {
    width: "100%",
    height: 400,
    objectFit: "cover",
    objectPosition: "100% center",
  },
  container: {
    position: "relative",
  },
  textBlock: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: 20,
    margin: "auto",
  },
};

export default class Offers extends React.Component {
  taxCalculation(tax, value) {
    try {
      tax = Number(tax);
      value = Number(value);
      value = (value + (value * tax) / 100).toFixed(0);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  getSlides() {
    return this.props.offers.map((x) => {
      let offer = x.offerends;
      try {
        offer = Number(offer);
      } catch (e) {
        offer = 0;
      }
      let discount = this.taxCalculation(x.tax, x.discount);
      let url = "/product?shop=" + x.shop_id + "&product=" + x.product_id;
      if (offer > 0) {
        return (
          <a href={url} target="_blank">
            <div style={style.container}>
              <img style={style.image} src={x.feature_image} alt="" />
              <div style={style.textBlock}>
                <h4>{x.product_name}</h4>
                <p>Discount: Rs.{discount}</p>
              </div>
            </div>
          </a>
        );
      }
    });
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return <Slider {...settings}>{this.getSlides()}</Slider>;
  }
}
