import React, { Component } from "react";
import {
  Image,
  Modal,
  Button,
  Card,
  Grid,
  Icon,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const style = {
  image: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
  },
  card: {
    textAlign: "left",
    maxWidth: 200,
  },
  description: {
    maxHeight: "2.6em",
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  content: {
    minHeight: 100,
    maxHeight: 100,
  },
};

class Searchshop extends Component {
  render() {
    let getresult = <div />;
    if (this.props.data) {
      if (this.props.data.length >= 1) {
        getresult = this.props.data.map((z) => {
          let fetureimage = z.feature_image ? (
            <img style={style.image} src={z.feature_image} alt="" />
          ) : (
            <Image style={style.image} src="/shop.jpg" />
          );
          let name = z.shop_name;
          let path = name.split(" ").join("-");
          let url = "/" + path;
          if (z.type === "shopdetail") {
            return (
              <Grid.Column mobile={12} tablet={3} computer={4}>
                <Link to={url}>
                  <Card fluid style={style.card}>
                    {fetureimage}
                    <Card.Content style={style.content}>
                      <Card.Header>{name}</Card.Header>
                      <Card.Meta>
                        <span className="date"> </span>
                      </Card.Meta>
                      <Card.Description style={style.description}>
                        {"Shop"}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <a>
                        <Icon name="boxes" />
                        {z.count + " Products"}
                      </a>
                    </Card.Content>
                  </Card>
                </Link>
              </Grid.Column>
            );
          }
        });
      } else {
        return (
          <div>
            <center style={{ color: "red", fontSize: 22, paddingTop: "22%" }}>
              No Shop is Found!!!!!
            </center>
          </div>
        );
      }
    } else {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    return (
      <Grid style={{ paddingLeft: "3%", paddingRight: "2%", paddingTop: "1%" }}>
        {getresult}
      </Grid>
    );
  }
}

export default Searchshop;
