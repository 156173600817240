import React from "react";
import {
  Card,
  Icon,
  Button,
  Input,
  Grid,
  Dimmer,
  Loader,
  Header,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import InputBase from "@material-ui/core/InputBase";
// import Divider from '@material-ui/core/Divider';
import IconButton from "@material-ui/core/IconButton";
import Minimize from "@material-ui/icons/RemoveCircleOutline";
import Add from "@material-ui/icons/AddCircleOutline";
// import DirectionsIcon from '@material-ui/icons/Directions';
// import userId from '../functions/userid';
import userID from "../functions/userid";
import server from "../functions/server";
// import domain from '../functions/domain';
import cartss from "../functions/cart";
import EnquireNow from "../enquiry";
import Network from "../Utilis/Network";

const style = {
  image: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: 200,
    maxHeight: 200,
    objectFit: "cover",
  },
  card: { textAlign: "left" },
  description: {
    minHeight: 40,
    maxHeight: 40,
    lineHeight: "1.3em",
    textOverflow: "ellipsis",
    display: "block",
    color: "black",
    padding: "2%",
    wordWrap: "break-word",
    overflow: "hidden",
  },
  productName: {
    fontWeight: "bold",
    color: "black",
    fontSize: 15,
    paddingLeft: "2%",
    paddinRight: "2%",
  },
};

export default class Product extends React.Component {
  state = { load: false };

  changeQty = (cart, productId, type) => (event) => {
    let userId = userID();
    if (userId == null) {
      let url = window.location.href;
      window.location.href = "https://you.strackit.com?redirectto=" + url;
      return;
    }
    if (type === "plus") {
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart + 1;
    } else if (type === "minus") {
      if (cart === 0) {
        alert("Value is Negative!!!");
        return;
      }
      try {
        cart = Number(cart);
      } catch (e) {}
      cart = cart - 1;
    } else {
      cart = event.target.value;
    }
    let data = this.props.data;
    data.map((x) => {
      if (x.type === "product") {
        if (x.id === productId) {
          let no_stock = 0;
          try {
            no_stock = Number(x.no_stock);
            cart = Number(cart);
            if (cart > no_stock) {
              alert("Out Of Stock");
            } else {
              x.cart = cart;
              this.cartChange(cart, x.shop_id, x.product_id);
            }
          } catch (e) {}
        }
      }
    });
    this.props.update(data);
  };

  setCookies(product_id, userId, shopid, cart) {
    let carts = cartss();
    let arr = [];
    if (carts === 0) {
      carts = [];
    } else {
      try {
        carts = JSON.parse(carts); 
      } catch (e) {
        carts = [];
      }
    }
    let value = 0;
    carts.map((c) => {
      if (c.productId === product_id && c.user === userId) {
        value = 1;
      }
    });
    if (value === 1) {
      carts.map((c) => {
        if (c.productId === product_id && c.user === userId) {
          c.quantity = cart;
        }
      });
    } else {
      arr.push({
        productId: product_id,
        user: userId,
        shop_id: shopid,
        cart: cart,
        shopName: this.props.shopName,
      });
      carts = carts.concat(arr);
    }
    carts = JSON.stringify(carts);
    document.cookie = "cart =" + carts;
    this.props.badgeUpdate();
  }

  cartChange(cart, shopid, product_id) {
    let context = this;
    let userId = userID();
    let payload = {
      productId: product_id,
      userId: userId,
      shopId: shopid,
      qty: cart,
    };
    this.setState({ load: true, loadMsg: "Adding To Cart" });
    let url = server() + "/product/cart.php";

    Network.POST(
      url,
      payload,
      (data) => {
        if (data === 1) {
          context.setState({ load: false, loadMsg: "" });
          context.setCookies(product_id, userId, shopid, cart);
        }
      },
      (err) => {
        alert(err);
      }
    );
  }

  render() {
    let { product, shopName } = this.props;
    let cart = product.cart;
    let productId = product.id;
    let shopId = product.shop_id;
    let tax = product.tax;
    let name = product.product_name;
    let prize = product.prize;
    let description = product.description;
    let discount = product.discount;
    if (name.length > 18) {
      name = name.substring(0, 18) + "... ";
    }
    if (description.length > 60) {
      description = description.substring(0, 60) + "... " + "Read More";
    }
    let image = product.feature_image;
    let offerends = product.offerends;

    let cityUrl;
    try {
      cityUrl = "&city=" + this.props.city.split(" ").join("-");
      cityUrl = this.props.city.split(" ").join("-") + "/";
    } catch (e) {}
    if (this.props.area && this.props.area.length > 0) {
      cityUrl =
        "&area=" +
        this.props.area.split(" ").join("-") +
        "&city=" +
        this.props.city.split(" ").join("-");
      cityUrl =
        this.props.city.split(" ").join("-") +
        "/" +
        this.props.area.split(" ").join("-") +
        "/";
    }
    let url =
      "/product?name=" +
      encodeURI(product.product_name.split(" ").join("-")) +
      cityUrl +
      "&shop=" +
      product.shop_id +
      "&product=" +
      product.product_id +
      "&shopName=" +
      shopName.split(" ").join("-");
    url =
      "/product/" +
      cityUrl +
      shopName.split(" ").join("-") +
      "/" +
      encodeURI(product.product_name.split(" ").join("-")) +
      "/" +
      product.shop_id +
      "/" +
      product.product_id;
    try {
      tax = Number(tax);
      prize = Number(prize);
      discount = Number(discount);
      offerends = Number(offerends);
      prize = Number(prize + (prize * tax) / 100).toFixed(0);
      discount = Number(discount + (discount * tax) / 100).toFixed(0);
    } catch (e) {}
    if (offerends > 0) {
      prize = (
        <span>
          <strike style={{ color: "red" }}>{"₹ " + prize}</strike>
          <div style={{ color: "green", fontWeight: "bold" }}>
            {"₹ " + discount}
          </div>
        </span>
      );
    } else {
      prize = (
        <span>
          <div style={{ color: "green", fontWeight: "bold" }}>
            <br />
            {"₹ " + prize}
          </div>
        </span>
      );
    }
    return (
      <center style={{ width: "100%" }}>
        <Dimmer active={this.state.load} page>
          <Loader />
          <Header as="h2" inverted style={{ marginTop: 100 }}>
            {this.state.loadMsg}
          </Header>
        </Dimmer>

        <Card fluid style={style.card}>
          <Link to={url}>
            <div style={{ cursor: "pointer" }}>
              {image ? (
                <img style={style.image} src={image} alt="" />
              ) : (
                <img
                  style={style.image}
                  src={require("../media/empty.jpg")}
                  alt=""
                />
              )}
              <Card.Content>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div style={style.productName}>{name}</div>
                <Card.Meta>
                  <span className="date"> </span>
                </Card.Meta>
                <Card.Description style={style.description}>
                  {/* {description} */}

                  <p
                      dangerouslySetInnerHTML={{
                      __html: description.includes("http-equiv")
                          ? ""
                          : description
                          ? description
                          : "",
                      }}
                  />

                </Card.Description>
              </Card.Content>
            </div>
          </Link>
          <br />
          <Card.Content
            extra
            style={{ display: "flex", marginTop: "auto", marginBottom: "auto" }}
          >
            {product.view_price === "0" ? (
              <div style={{ marginTop: "4%" }}>
                <EnquireNow
                  productName={name}
                  productId={productId}
                  shopId={shopId}
                  shopName={shopName}
                  type="link"
                />
                <br />
              </div>
            ) : (
              <Grid>
                <Grid.Column mobile={8} tablet={8} computer={9}>
                  <span>{prize}</span>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={7}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <IconButton
                      style={{ width: "20%" }}
                      className={style.iconButton}
                      aria-label="menu"
                    >
                      <Minimize
                        onClick={this.changeQty(cart, productId, "minus")}
                      />
                    </IconButton>
                    <InputBase
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={this.changeQty(cart, productId, "other")}
                      value={cart}
                      placeholder="Add"
                    />
                    <IconButton
                      style={{ width: "20%" }}
                      className={style.iconButton}
                      aria-label="menu"
                    >
                      <Add onClick={this.changeQty(cart, productId, "plus")} />
                    </IconButton>
                  </div>
                </Grid.Column>
              </Grid>
            )}
          </Card.Content>
        </Card>
      </center>
    );
  }
}
