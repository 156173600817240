import React, { Component } from "react";
import Viewcart from "./viewcart";
import $ from "jquery";
import server from "../../functions/server";
import { Card, Grid, Loader, Button } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import UserId from "../../functions/userid";
import Network from "../../Utilis/Network";

class Cart extends Component {
  state = {
    cart: [],
    total: 0,
    item: 0,
    realPrice: 0,
    loading: false,
    errorDialog: false,
  };

  componentDidMount() {
    this.viewOrderProduct();
  }

  viewOrderProduct() {
    let context = this;
    let userId = UserId();
    let url = new URL(window.location.href);
    let shopname = url.searchParams.get("shopname");
    let shopId = url.searchParams.get("shop");
    let data1;
    let urls;
    if (shopname) {
      shopname = shopname.replace(/-/g, " ");
      data1 = { userId: userId, shopName: shopname };
      urls = server() + "/cart/getbyname.php";
    } else {
      data1 = { userId: userId, shopId: shopId };
      urls = server() + "/cart/get.php";
    }
    context.setState({ loading: false });

    Network.POST(
      urls,
      data1,
      (data) => {
        console.log(data);
        let prize = 0;
        let realPrice = 0;
        let shop_id;
        data.map((c) => {
          let price = c.prize;
          let quantity = c.quantity;
          let offerends = c.offerends;
          let discount = c.discount;
          let tax = c.tax;
          shop_id = c.shop_id;
          discount = Number(discount);
          tax = Number(tax);
          price = Number(price);
          quantity = Number(quantity);
          offerends = Number(offerends);
          if (offerends <= 0) {
            price = price * quantity;
            price = price + (price * tax) / 100;
            prize = prize + price;
          } else {
            if (offerends <= quantity) {
              prize =
                prize +
                (discount * offerends + (discount * offerends * tax) / 100);
              prize =
                prize +
                (price * (quantity - offerends) +
                  (price * (quantity - offerends) * tax) / 100);
            } else {
              prize =
                prize +
                (discount * quantity + (discount * quantity * tax) / 100);
            }
          }
        });
        context.setState({
          cart: data,
          total: prize,
          item: data.length,
          loading: false,
          shop_id: shop_id,
        });
        context.props.refereshCart(data);
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  removeItem(id) {
    let cart = this.state.cart;
    let dele = [];
    cart.map((del) => {
      if (del.cartId === id) {
        return;
      } else {
        dele.push(del);
      }
    });
    let prize = 0;
    let realPrice = 0;
    dele.map((c) => {
      let price = c.prize;
      let quantity = c.quantity;
      let offerends = c.offerends;
      let discount = c.discount;
      let tax = c.tax;
      discount = Number(discount);
      tax = Number(tax);
      price = Number(price);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        price = price * quantity;
        price = price + (price * tax) / 100;
        prize = prize + price;
      } else {
        if (offerends <= quantity) {
          prize =
            prize + (discount * offerends + (discount * offerends * tax) / 100);
          prize =
            prize +
            (price * (quantity - offerends) +
              (price * (quantity - offerends) * tax) / 100);
        } else {
          prize =
            prize + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
    });
    this.setState({ cart: dele, total: prize, item: dele.length });
    this.props.refereshCart(dele);
  }

  updateChart(updateChart) {
    let prize = 0;
    let realPrice = 0;
    updateChart.map((c) => {
      let price = c.prize;
      let quantity = c.quantity;
      let offerends = c.offerends;
      let discount = c.discount;
      let tax = c.tax;
      discount = Number(discount);
      tax = Number(tax);
      price = Number(price);
      quantity = Number(quantity);
      offerends = Number(offerends);
      if (offerends <= 0) {
        price = price * quantity;
        price = price + (price * tax) / 100;
        prize = prize + price;
      } else {
        if (offerends <= quantity) {
          prize =
            prize + (discount * offerends + (discount * offerends * tax) / 100);
          prize =
            prize +
            (price * (quantity - offerends) +
              (price * (quantity - offerends) * tax) / 100);
        } else {
          prize =
            prize + (discount * quantity + (discount * quantity * tax) / 100);
        }
      }
    });
    this.setState({
      cart: updateChart,
      total: prize,
      item: updateChart.length,
    });
    this.props.refereshCart(updateChart);
  }

  orderCart() {
    let cart = [];
    this.state.cart.map((z) => {
      cart.push(
        <Viewcart
          shop_id={this.state.shop_id}
          updateChart={this.updateChart.bind(this)}
          tax={z.tax}
          cart={this.state.cart}
          removeItem={this.removeItem.bind(this)}
          id={z.id}
          cartId={z.cartId}
          quantity={z.quantity}
          product_name={z.product_name}
          prize={z.prize}
          no_stock={z.no_stock}
          discount={z.discount}
          offerends={z.offerends}
          feature_image={z.feature_image}
        />
      );
    });
    return cart;
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  valueRoundOff() {
    let prize, tax, quantity;
    let total = 0;
    this.state.cart.map((c) => {
      prize = this.convertToNumber(c.prize);
      tax = this.convertToNumber(c.tax);
      quantity = this.convertToNumber(c.quantity);
      total = total + (prize + (prize * tax) / 100) * quantity;
    });
    return total.toFixed(2);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    let cart = this.orderCart();
    let total = this.state.total;
    try {
      total = Number(total).toFixed(2);
    } catch (e) {
      total = 0;
    }
    return (
      <div style={{ paddingTop: "2%" }}>
        <Card fluid>
          <Card.Content style={{ backgroundColor: "#74D4F4" }}>
            <Card.Header>My Cart</Card.Header>
          </Card.Content>
          <Card.Content>{cart}</Card.Content>
          <Card.Content>
            <Grid>
              <Grid.Column mobile={8} tablet={8} computer={11}>
                <h5>Item</h5>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={5}>
                <h5>{this.state.item}</h5>
              </Grid.Column>
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={8}
                style={{ backgroundColor: "green", color: "white" }}
              >
                <h4>Total</h4>
              </Grid.Column>
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={8}
                style={{ backgroundColor: "green", color: "white" }}
              >
                <h4 style={{ float: "right" }}>Rs: {this.valueRoundOff()}</h4>
              </Grid.Column>
            </Grid>
          </Card.Content>
        </Card>
        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Cart;
