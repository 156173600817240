import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import LanguageIcon from "@material-ui/icons/Language";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/ShoppingCart";
import MoreIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Shops from "./shops";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import userDp from "../functions/userdp";
import userId from "../functions/userid";
import server from "../functions/server";
import domain from "../functions/domain";
import cart from "../functions/cart";
import { Icon, Input, Dropdown, Loader, Grid } from "semantic-ui-react";
import Checkbox from "@material-ui/core/Checkbox";
import Searchshop from "./searchshop";
import Product from "../shop/product";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Network from "../Utilis/Network";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class Home extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    badge: 0,
    data1: [],
    longitude: 0,
    latitude: 0,
    searchByType: "Shops",
    allshop: true,
    searchesProducts: [],
  };

  handleProfileMenuOpen = (event) => {
    if (!userId()) {
      window.location.href =
        "https://you.strackit.com?redirectto=https://shop.strackit.com";
      return;
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    if (!userId()) {
      window.location.href =
        "https://you.strackit.com?redirectto=https://shop.strackit.com";
      return;
    }
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  gotoMyorder() {
    window.location.href = domain() + "myorders";
  }

  socialMedia() {
    window.location.href = domain() + "socialmedia?shopname=ALL";
  }

  handleChanges = (name) => (event) => {
    this.setState({ searching: event.target.value });
  };

  searchProduct() {
    let context = this;
    let search = this.state.searching;
    if (!search) {
      this.setState({ searchAllow: false });
      return;
    }
    let payload = [];
    let url;
    if (this.state.searchByType === "Shops") {
      payload = {
        search: search,
        longitude: this.state.longitude,
        latitude: this.state.latitude,
        allshop: this.state.allshop,
      };
      url = server() + "/product/search.php";
      context.setState({ load: true, message: "Searching Shops" });
    } else {
      payload = { search: search, userid: userId() };
      url = server() + "/product/searchproducts.php";
      context.setState({ load: true, message: "Searching Products" });
    }
    Network.POST(
      url,
      payload,
      (data) => {
        try {
          if (context.state.searchByType === "Shops") {
            context.setState({ data1: data, searchAllow: true, load: false });
          } else {
            context.setState({
              searchesProducts: data,
              data1: data,
              load: false,
            });
          }
        } catch (e) {
          context.setState({ load: false });
        }
      },
      (err) => {
        context.setState({ load: false });
        console.log(err);
      }
    );
  }

  goProfile() {
    window.location.href = "https://you.strackit.com/";
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location.reload(true);
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  searyBy = (f, { value }) => {
    this.setState({ searchByType: value });
  };

  badgeUpdate() {}

  updateData() {}

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.goProfile.bind(this)}>Profile</MenuItem>
        <MenuItem onClick={this.deleteAllCookies.bind(this)}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    let options = [
      { key: "Shops", text: "Shops", value: "Shops" },
      { key: "Products", text: "Products", value: "Products" },
    ];

    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              Strackit Shops
            </Typography>
            <div className={classes.search}>
              <Input
                label={
                  <Dropdown
                    onChange={this.searyBy}
                    style={{ width: 100 }}
                    defaultValue={this.state.searchByType}
                    options={options}
                  />
                }
                labelPosition="left"
                placeholder="Search..."
                onChange={this.handleChanges("search")}
              />
              <Button onClick={this.searchProduct.bind(this)}>Search</Button>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <FormControlLabel
                style={{ color: "white" }}
                control={
                  <Checkbox
                    checked={this.state.allshop}
                    onChange={this.handleChange("allshop")}
                  />
                }
                label="Show All Shops"
              />
              <IconButton color="inherit" onClick={this.socialMedia.bind(this)}>
                <LanguageIcon />
              </IconButton>
              <IconButton color="inherit" onClick={this.gotoMyorder.bind(this)}>
                <Icon name="shopping bag" size="small" />
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                {userDp() ? <AccountCircle /> : <AccountCircle />}
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        {this.state.load && <LinearProgress />}
        <div style={{ height: 100 }}>
          <div style={{ marginTop: "6%" }}>
            {this.state.searchByType === "Shops" ? (
              <div>
                {this.state.searchAllow ? (
                  <Searchshop
                    data={this.state.data1}
                    search={this.state.searching}
                  />
                ) : (
                  <div>
                    {this.state.longitude != null ? (
                      <Shops
                        longitude={this.state.longitude}
                        latitude={this.state.latitude}
                        allshop={this.state.allshop}
                      />
                    ) : (
                      <div style={{ paddingTop: "35%" }}>
                        <center>
                          <Loader />
                        </center>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Grid style={{ padding: "1%" }}>
                  {this.state.searchesProducts.map((x) => {
                    return (
                      <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Product
                          badgeUpdate={this.badgeUpdate.bind(this)}
                          product={x}
                          data={this.state.searchesProducts}
                          shopName={x.shop_name}
                          update={this.updateData.bind(this)}
                        />
                      </Grid.Column>
                    );
                  })}
                </Grid>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
