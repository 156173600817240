import React, { Component } from "react";
import Title from "./title";
import { Icon, Step, Grid, Card, Button, Header } from "semantic-ui-react";
import "./order.css";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Buttons from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import $ from "jquery";

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <div>
        <Title title="Payment" />
        <br />
        <br />

        <center style={{ marginTop: "10%" }}>
          <Card id="login">
            <Header
              as="h2"
              icon
              textAlign="center"
              style={{ marginTop: 50, color: "#9EB6CE" }}
            >
              <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <Header.Content>Your Order Placed Successfully</Header.Content>
            </Header>
            <div>
              Please pay your payment in shop and pickup your order as soon as
              possiable
            </div>
            <br />
            {this.state.loading == false && (
              <Grid>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    id="buttonClicked"
                    fluid
                    onClick={() => (window.location.href = "/")}
                  >
                    Back
                  </Button>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    id="buttonClicked"
                    fluid
                    onClick={() => (window.location.href = "/myorders")}
                  >
                    Go to my orders
                  </Button>
                </Grid.Column>
              </Grid>
            )}
            {this.state.loading && <CircularProgress />}
            <br />
          </Card>
        </center>
      </div>
    );
  }
}

export default Payment;
