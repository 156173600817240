import React, { Component } from "react";
import Title from "../title";
import server from "../functions/server";
import { Button, Card, Grid, Image, Modal } from "semantic-ui-react";
import Network from "../Utilis/Network";

class Socialmedia extends Component {
  state = {
    posts: [],
  };

  componentDidMount() {
    this.getPosts();
  }

  getPosts() {
    let context = this;
    let urls = new URL(window.location.href);
    let shopName = urls.searchParams.get("shopname");
    let url;
    let payload = [];
    if (shopName === "ALL") {
      url = server() + "/socialmedia/getallpost.php";
    } else {
      url = server() + "/socialmedia/getpostbyshopname.php";
      payload = { shopName: shopName };
    }
    console.log(url);
    Network.POST(
      url,
      payload,
      (data) => {
        try {
          context.setState({ posts: data });
        } catch (e) {
          alert("Error to Get Posts");
        }
      },
      (err) => {
        context.setState({ load: false });
        console.log(err)
      }
    );
  }

  openUrl(url) {
    if (url.includes("http")) {
      window.open(url);
    } else {
      window.open("https://" + url);
    }
  }

  render() {
    return (
      <div>
        <Title title="Social Media" />
        <br />
        <br />
        <br />
        <div
          style={{ paddingTop: "2%", paddingLeft: "1%", paddingRight: "1%" }}
        >
          {this.state.posts.map((c) => {
            return (
              <Card fluid>
                <Card.Content>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={5}>
                      <Image style={{ height: 280 }} src={c.image} fluid />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={11}>
                      <b style={{ fontSize: 18 }}>{c.title}</b>
                      <p style={{ fontSize: 14 }}>{c.description}</p>
                      {!c.url ? (
                        <div />
                      ) : (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={this.openUrl.bind(this, c.url)}
                        >
                          <u style={{ color: "blue" }}>{c.url}</u>
                        </div>
                      )}
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Socialmedia;
