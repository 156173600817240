import React, { Component } from 'react';
import { Image, Modal, Button } from 'semantic-ui-react';
import './product.css';

class Images extends Component {
  state = { };

  show = size => () => this.setState({ size: size, open: true })
  close = () => this.setState({ open: false })

  render() {
    return (
      <div>
        <Image src={this.props.image} onClick={this.show('tiny')} fluid id='image' style={{cursor: 'pointer', height: 100}} />
        <Modal size={this.state.size} open={this.state.open} onClose={this.close}>
          <Modal.Content>
            <Image src={this.props.image} fluid id='image' />
          </Modal.Content>
          <Modal.Actions>
            <Button positive icon='checkmark' labelPosition='right' onClick={this.close} content='Okay' />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default Images;
