import React, { Component } from "react";
import {
  Card,
  Grid,
  Icon,
  Rating,
  Form,
  TextArea,
  Button,
  Loader,
} from "semantic-ui-react";
import Status from "./status";
import "./myorder.css";
import $ from "jquery";
import server from "../../functions/server";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import UserId from "../../functions/userid";
import Network from "../../Utilis/Network";

class View extends Component {
  state = {
    rating: 0,
    error: "",
    errorDialog: false,
    loading: false,
  };

  postRatingAndReview() {
    let context = this;
    let userId = UserId();
    let review = document.getElementById("review").value.trim();
    if (this.state.rating === 0) {
      context.setState({ error: "Please give rating for the products!!!" });
      return;
    }
    if (review.length <= 1) {
      context.setState({ error: "Please give review for the products!!!" });
      return;
    }
    let data1 = {
      userId: userId,
      productId: this.props.productId,
      orderId: this.props.orderID,
      rating: this.state.rating,
      review: review,
    };
    context.setState({ loading: true });
    let url = server() + "/ratingandreview/postrating.php";
    Network.POST(
      url,
      data1,
      (data) => {
        console.log(data);
        data = Number(data);
        context.setState({ loading: false });
        if (data === 1) {
          context.updateorders(review);
        }
      },
      (err) => {
        context.setState({ errorDialog: true, loading: false });
      }
    );
  }

  updateorders(review) {
    let myorder = this.props.myorder;
    for (var i = 0; i < myorder.length; i++) {
      if (myorder[i].id === this.props.orderID) {
        myorder[i].review = review;
        myorder[i].rating = this.state.rating + "";
      }
    }
    this.props.updateorders(myorder);
  }

  handleRate = (e, { rating }) => this.setState({ rating: rating, error: "" });

  clearerror() {
    this.setState({ error: "" });
  }

  errorDialog() {
    this.setState({ errorDialog: false });
  }

  dateFormat(date) {
    let dd = date.split(" ")[0];
    let tt = date.split(" ")[1];
    dd =
      dd.split("-")[2] +
      "-" +
      dd.split("-")[1] +
      "-" +
      dd.split("-")[0] +
      " " +
      tt;
    return dd;
  }

  render() {
    let rating = <div />;

    if (this.props.rating == null) {
      if (this.props.status === 5) {
        rating = (
          <Card.Content>
            <Grid>
              <Grid.Column mobile={16} tablet={16} computer={1}>
                <b>Rating</b>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <Rating
                  maxRating={5}
                  icon="star"
                  defaultRating={0}
                  onRate={this.handleRate}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={1}>
                <b>Review</b>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={10}>
                <Form>
                  <TextArea
                    autoHeight
                    placeholder="Review"
                    onChange={this.clearerror.bind(this)}
                    id="review"
                  />
                  <center style={{ color: "red" }}>{this.state.error}</center>
                </Form>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <Button positive onClick={this.postRatingAndReview.bind(this)}>
                  POST
                </Button>
              </Grid.Column>
            </Grid>
          </Card.Content>
        );
      } else {
        rating = <div />;
      }
    } else {
      rating = (
        <Card.Content>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={1}>
              <b>Rating</b>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Rating
                disabled
                maxRating={5}
                defaultRating={this.props.rating}
                style={{ color: "red" }}
                size="tiny"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={1}>
              <b>Review</b>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={12}>
              <p>{this.props.review}</p>
            </Grid.Column>
          </Grid>
        </Card.Content>
      );
    }
    return (
      <div>
        {this.props.quantity === 0 ? (
          <Card fluid>
            <Card.Content>
              <Card.Header>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={14}>
                    Delivery Charge
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={2}>
                    <h4>{"Rs " + this.props.price}</h4>
                  </Grid.Column>
                </Grid>
              </Card.Header>
            </Card.Content>
          </Card>
        ) : (
          <Card fluid>
            <Card.Content>
              <Card.Header>{this.props.product_name}</Card.Header>
            </Card.Content>
            <Card.Content>
              <Grid>
                <Grid.Column mobile={8} tablet={8} computer={11}>
                  <Grid>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      Quality : {this.props.quantity}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h4>Price : {this.props.price}</h4>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      Ordered on: {this.dateFormat(this.props.date)}
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      Shop Name: {this.props.shopName}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={5}
                  style={{ display: "none" }}
                >
                  <b style={{ fontSize: "15px" }}>Delivery address</b>
                  <div style={{ padding: "2%" }}>
                    <b style={{ color: "#0D7DA2", fontSize: "17px" }}>
                      {this.props.name}
                    </b>
                    <br />
                    {this.props.street},<br />
                    {this.props.city},<br />
                    {this.props.state},<br />
                    {this.props.country} - {this.props.pincode}
                    <br />
                    <br />
                    <b srtle={{ fontSize: "18px" }}>
                      Phone number: {this.props.phone}
                    </b>
                  </div>
                </Grid.Column>
              </Grid>
            </Card.Content>
            <Card.Content>
              <Status
                shopId={this.props.shopId}
                productId={this.props.productId}
                product_id={this.props.product_id}
                status={this.props.status}
                updateMyOrder={this.props.updateMyOrder}
                orderID={this.props.orderID}
              />
            </Card.Content>
            {rating}
          </Card>
        )}
        <Dialog
          open={this.state.errorDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <DialogContentText>Try again!!!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.errorDialog.bind(this)}>Okey</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.loading}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" id1="widthdialog">
            {"LOADING..."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Loader active inline="centered" />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default View;
